import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmenuComponent } from './submenu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { ClientMenuModule } from '../controlesViejosARevisar/client-menu/client-menu.module';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    SubmenuComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatBadgeModule,
    MatTabsModule,
    ClientMenuModule,
    MatTooltipModule
  ],
  exports: [
    SubmenuComponent,
  ]
})
export class SubmenuModule { }
