import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsComponent } from './notifications.component';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { ClickOutsideModule } from 'ng-click-outside';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        NotificationsComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        ClickOutsideModule,
        MatBadgeModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatMenuModule,
        MatTooltipModule
    ],
    exports: [
        NotificationsComponent
    ]
})
export class NotificationsModule {
}