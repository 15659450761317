import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { RouterModule } from '@angular/router';

import { ProfileComponent } from './profile.component';
import {MatMenuModule} from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { CommonModulesModule } from '../../../core/validators/common-modules/common-modules.module';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    declarations: [
        ProfileComponent
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        MatIconModule,
        RouterModule,
        CommonModulesModule,
        MatCardModule
    ],
    exports:[
        ProfileComponent
    ]
})
export class ProfileModule
{
}