<section class="results">

    <div class="results_container">
        <div class="results_header" (click)="showPedidos = !showPedidos" matRipple>
            <mat-icon>{{showPedidos ? 'expand_less' : 'expand_more'}}</mat-icon>
            <h6>Pedidos ({{results.listaPedidos.length}} resultados)</h6>
        </div>
        <ul class="results_list" *ngIf="showPedidos">
            <a *ngFor="let pedido of results.listaPedidos" class="results_item" 
                matRipple [href]="'/pedido/' + pedido.id">
                <div class="id">
                    <p>AR -</p>
                    <p>#{{pedido.id}}</p>
                </div>

                <div class="ubicacion">
                    <p class="direccion">{{pedido.direccion}}</p>
                    <p class="localidad">{{pedido.localidad}}</p>
                </div>

                <div class="services">
                    <mat-icon *ngFor="let servicio of pedido.serviceIcon index as i;"
                        [class]="pedido.serviceStage[i] == '340' ? 'finalizado' : 'enProceso'">
                        {{servicio}}
                    </mat-icon>
                </div>

                <div class="results_item-info">
                    <div class="creadoPor">
                        <p>Creado por: {{pedido.creadorPedido}} - {{pedido.nombreSucursal}}</p>
                    </div>

                    <div class="sucursal">
                    </div>

                    <div class="recibePersona">
                        <p>Recibe: {{pedido.recibePersona}} - {{pedido.recibeContacto}}</p>
                    </div>
                </div>
            </a>
        </ul>

        <div class="results_header" (click)="showMovimientos = !showMovimientos" matRipple>
            <mat-icon>{{showMovimientos ? 'expand_less' : 'expand_more'}}</mat-icon>
            <h6>Movimientos ({{results.listaMovimientos.length}} resultados)</h6>
        </div>

        <ul class="results_list" *ngIf="showMovimientos">
            <a *ngFor="let movimiento of results.listaMovimientos" class="results_item" matRipple>
                <div class="id movID">
                    <p>Mov: {{movimiento.id}}</p>
                    <p>#{{movimiento.pedidoID}}</p>
                </div>

                <div class="fecha">
                    <p>{{formatearFecha(movimiento.fecha)}}</p>
                </div>

                <div class="datosPersona movPer">
                    <p style="font-weight: 600;">{{movimiento.direccion}}</p>
                    <p>{{movimiento.localidad}}</p>
                </div>

                <div class="data">
                    <p>Tipo: Relevamiento</p>
                    <p>Estado: {{getEstadoMovimiento(movimiento)}}</p>
                    <p>Monto: ${{getNumberWithCommas(movimiento.monto)}}</p>
                </div>

            </a>
        </ul>

    </div>

</section>