<section>
    <mat-icon class="icono-noti-topbar" [matBadge]=notSinLeer matBadgeSize="small" matBadgeColor="warn" [matBadgeHidden]="notSinVer <= 0" (click)="!cardOpen ? abrirNotificaciones() : cerrarNotificaciones()">notifications</mat-icon>

    <mat-card class="contenedor-notificaciones" [ngClass]="{'animacion-abriendo': animacion == 'fadeIn', 'animacion-cerrando': animacion == 'fadeOut'}" *ngIf="cardOpen">
        <div class="cabecera">
            <h1 class="titulo">Notificaciones</h1>
            <div class="contenedor">
                <div class="filtros">
                  <span [ngClass]="claseFiltroTodos" (click)="mostrarTodas()">Todas</span>
                  <span [ngClass]="claseFiltroNoLeido" (click)="mostrarNoLeidas()">No leídas</span>
                </div>

                <div [matTooltip]="'Acciones'" [matMenuTriggerFor]="menu" class="acciones">
                   <mat-icon>more_vert</mat-icon>
               </div>

               <mat-menu #menu="matMenu">
                   <button mat-menu-item class="boton-accion" (click)="verTodasLasNotificaciones()">
                       <mat-icon>done_all</mat-icon>
                       Leer todo
                   </button>
               </mat-menu>
            </div>
        </div>
        <div class="cuerpo">

            <div class="cargando" *ngIf="!notificacionesCargadas">
                <mat-spinner class="barra-cargando"></mat-spinner>
            </div>

            <div *ngIf="notificacionesCargadas">
                <div class="notificaciones" *ngFor="let notif of notificaciones; index as i">
                
                    <div class="contenedor-notificacion" (click)="leerNotif(notif.id, i)" (click)="irA(notif.redireccion)">
                        <div class="icono-notificacion">
                            <mat-icon class="icono" [ngClass]="{'leido': notif.leido === 0 }">{{notif.icono}}</mat-icon>
                        </div>
    
                        <div class="contenido-notificacion">
                            <div class="titulo-notificacion" [ngClass]="{'leido': notif.leido === 0 }">
                                {{notif.titulo}}
                            </div>
    
                            <div class="cuerpo-notificacion" [ngClass]="{'leido': notif.leido === 0 }">
                                <p>{{notif.cuerpo}}</p>
                            </div>
    
                            <div class="fecha-notificacion" [ngClass]="{'leido': notif.leido === 0 }">
                                {{notif.fecha}}
                            </div>
                        </div>
    
                        <div class="estado-notificacion" click>
                            <mat-icon class="icono-sin-leer" *ngIf="notif.leido === 0;">circle</mat-icon>
                        </div>
    
                    </div>
           
                </div>
    
                <div *ngIf="notificaciones.length === 0" class="sin-notificaciones">
                    <span>No hay notificaciones</span>
                </div>
    
            </div>
         
        </div>
    </mat-card>

    <!-- <mat-icon [matBadge]=notSinLeer matBadgeSize="small" matBadgeColor="warn" [matBadgeHidden]="notSinLeer <= 0"
    [matMenuTriggerFor]="notifMenu" style="color: white;">notifications</mat-icon> -->

    <!-- <mat-menu #notifMenu class="notification-menu" >
        <div class="notification-header" mat-menu-item>
            <h2>Notificaciones</h2>
        </div>

        <mat-action-list class="notificactionsFilters">
            <mat-list-item #allNotifications class="all activeFilter" (click)="onShowAllNotifications()"> 
                <span matListItemTitle> Todas </span>
            </mat-list-item>
            <mat-list-item ngModel #missedNotifications class="missed" (click)="onShowMissedNotifications()">
                <span matListItemTitle> No leidas </span>
            </mat-list-item>
        </mat-action-list>
        

        <div class="notification-body" mat-menu-item (scroll)="onScrollCheck($event)">
            <div>
           
                <p class="wihoutNotifications" *ngIf="notificaciones.length == 0">No hay notificaciones</p>
                <div [class]="'notification '+notif.class" *ngFor="let notif of notificaciones; index as i" >
                    <div class="noti">
                        <div class="notification-logo">                        
                            <mat-icon [ngStyle]="{'color': notif.leido ? 'rgb(184, 184, 184)' : '#328DD0'}">{{notif.icono}}</mat-icon>
                        </div>
                        
                        <div class="notification-container" (click)="leerNotif(notif.id, i)">
                            <div class="notification-title">
                                <h3>{{notif.titulo}}</h3>
                            </div>

                            <div class="notification-text">
                                <p>{{notif.cuerpo}}</p>
                            </div>

                            <div >
                                <p>{{notif.fecha }}</p>
                            </div>

                            
                        </div>

                        <div class="notification-status">
                            <mat-icon class="notifUnread" *ngIf="notif.leido==0;">circle</mat-icon>
                        </div>
                    
                    </div>
                </div>

                <div style="margin-bottom: 1rem;" *ngIf="isLoadingMore">

                    <loading></loading>
                </div>
            </div>
        </div>
    </mat-menu> -->


</section>
