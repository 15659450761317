import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/app/global/Global';
import { GetBalance, PostPayment } from '../interfaces/Payment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})

export class CajaService {
  constructor(private httpClient: HttpClient) { }

  getSaldo(datosCliente?:any,independiente?:boolean,independienteTipo?:number): Observable<GetBalance> {
    let url = Global.url + '/api/caja/saldo';
    return this.httpClient.post<GetBalance>(url,{datosCliente,independiente,independienteTipo} ,{
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getDescuento(idMovimiento:number): Observable<GetBalance> {
    let url = Global.url + `/api/caja/descuento/${idMovimiento}`;
    return this.httpClient.get<GetBalance>(url ,{
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getDatosCajaUsuario(){
    let url = Global.url + '/api/nocliente/caja';
    return this.httpClient.get(url,{
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getPedidosSinMovimientoFinalizado(filtros:any){
    let url = Global.url + '/api/nocliente/pedido-sin-movimiento/finalizado';
    return new Promise(res => {
      this.httpClient.post(url,{filtros}, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(response => {
        res(response)
      })
    })
  }

  
  tienePermiso(){
    let url: string = Global.url + `/api/cliente/permisoCaja`;
    return new Promise(res => {
      this.httpClient.get(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(response => {
        res(response)
      })
    })
  }

  getPrecompra(precompra: number)
  {
    let url: string = Global.url + `/api/cliente/precompra/${precompra}`;
    return new Promise(res => {
      this.httpClient.get(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(response => {
        res(response)
      })
    })
  }

  getCargaSaldo(id: number)
  {
    let url: string = Global.url + `/api/cargaSaldo/${id}`;
    return new Promise(res => {
      this.httpClient.get(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(response => {
        res(response)
      })
    })
  }

  accionCargarSaldo(body: any){
    let url = Global.url + '/api/cliente/caja/cargaSaldo';
    return new Promise(async (res, rej) => {
      this.httpClient.put(url, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }
  accionAprobarMovimiento(body: any){
    let url = Global.url + '/api/sa/caja/aprobarMovimiento';
    return new Promise(async (res, rej) => {
      this.httpClient.put(url, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  accionAprobarPrecompra(mov:any, apruebaRechaza:boolean){
    let url = Global.url + '/api/sa/caja/aprobar-precompra';
    return new Promise(async (res, rej) => {
      this.httpClient.put(url, {mov,apruebaRechaza}, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  /*getMovimientos(queries: any | undefined) {
    let url = Global.url + '/api/cliente/caja/movimientos';
    return this.httpClient.post(url, {
      queries
    } , {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }*/

  getUsuariosMovimientosSA(user: string){
    const url = Global.url + '/api/sa/caja/cliente/miembros/' + user; 

    return this.httpClient.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getSaldoGastadoPorUsuario(uuid: string){
    const url = Global.url + '/api/sa/caja/cliente/saldo-gastado/' + uuid

    return this.httpClient.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getMovimientosRelevador() {
    let url = Global.url + '/api/relevador/caja';
    return new Promise(async (res) => {
      this.httpClient.get(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  getFacturasRelevador() {
    let url = Global.url + '/api/relevador/caja/facturas';
    return new Promise(async (res) => {
      this.httpClient.get(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  // getServicios() {
  //   let url = Global.url + '/api/cliente/caja/servicios';
  //   return this.httpClient.get(url, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }

  getServiciosRelevador() {
    let url = Global.url + '/api/relevador/caja/servicios';
    return new Promise(async (res) => {
      this.httpClient.get(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  postPagoSaldo(bodyC: any,datosCliente?:any): Promise<PostPayment> {
    let url = Global.url + '/api/cliente/caja/pagarsaldo';
    return new Promise(async (res, rej) => {
      this.httpClient.post(url, { bodyC,datosCliente }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe((data: PostPayment) => {
        res(data);
      });
    });
  }

  agregarCuentaCorriente(idMovimiento:number, uuidCliente?:string){
    let url = Global.url + '/api/cliente/caja/agregar/cuentacorriente';
    return new Promise(async (res, rej) => {
      this.httpClient.put(url,{idMovimiento,uuidCliente}, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe((data: PostPayment) => {
        res(data);
      });
    });
  }

  pagarCuentaCorriente(){
    let url = Global.url + '/api/cliente/caja/pagar/cuentacorriente';
    return new Promise(async (res, rej) => {
      this.httpClient.put(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe((data: PostPayment) => {
        res(data);
      });
    });
  }

  postPagoComprobante(comprobante: any, pagarCuentaCorriente:boolean) {
    let url = Global.url + '/api/cliente/caja/pagarcomprobante';
    return new Promise(async (res, rej) => {
      this.httpClient.post(url, {comprobante, pagarCuentaCorriente}, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  getCajaNoCliente(tipo: string,queries: any | undefined = undefined ) {
    let url = Global.url + '/api/nocliente/caja/movimientos';
    return new Promise(async (res) => {
      this.httpClient.post(url, { queries,tipo }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  solicitarPago(movimientos:any,comprobanteDePago:any) {
    let url = Global.url + '/api/nocliente/caja/solicistar-pago';
    return new Promise(async (res) => {
      this.httpClient.post(url,{movimientos,comprobanteDePago}, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  getCajaNoClienteFiltrado(filtro:string,tipo: string,queries: any | undefined = undefined ) {
    let url = Global.url + '/api/nocliente/caja/movimientos-filtrado';
    return new Promise(async (res) => {
      this.httpClient.post(url, { filtro,queries,tipo }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  postSolicitarPago(viatico:number,condFinanciera:number, pedidosInvolucrados: string[] | number[], archivo: string = 'none', isReupload: boolean = false, movId?: number) {    
    let url = Global.url + '/api/sa/caja/solicitarpago';
    return new Promise(async (res) => {
      this.httpClient.post(url, {condFinanciera, pedidosInvolucrados, archivo, isReupload, movId,viatico}, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  cargarFactura(archivo: string = 'none', movId: number) {    
    let url = Global.url + '/api/sa/caja/solicitarpago';
    return new Promise(async (res) => {
      this.httpClient.post(url, {archivo, movId}, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  getSolicitudesDePagoId(movimientoId:string){
    const url = Global.url + `/api/sa/caja/solicitud-pago/${movimientoId}`;

    return this.httpClient.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getOrdersByMovementId(movId: string, category?: string){
    const url = Global.url + `/api/sa/caja/pedidos-movimiento/${movId}`;

    return this.httpClient.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  rejectPaymentRequest(payload: {id: string | number, estado: number, detalle: string}){
    const url: string = Global.url + '/api/sa/caja/rechazar-solicitud-pago';

    return this.httpClient.put(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  resolvePaymentRequest(receipts: FormData){
    const url: string = Global.url + '/api/sa/caja/resolver-solicitud-pago';

    return new Promise(async (res) => {
      this.httpClient.post(url, receipts, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  pagarMontoUsuario(receipts){
    const url: string = Global.url + '/api/sa/caja/pagar-monto';

    return new Promise(async (res) => {
      this.httpClient.post(url, receipts, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {
        res(data);
      });
    });
  }

  transferirPacks(packs, uuidTransferido){
    const url: string = Global.url + '/api/cliente/caja/packs/transferir';

    return this.httpClient.post(url, {packs, uuidTransferido}, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  transferirSaldo({total, uuidReceiver, transferReason}: {total: number, uuidReceiver: string, transferReason: string}){
    const url: string = Global.url + '/api/cliente/caja/saldo/transferir';

    return this.httpClient.post(url, {total, uuidReceiver, transferReason}, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  isCuentaCorriente(){
    const url: string = Global.url + '/api/cliente/caja/cuentaCorriente';

    return this.httpClient.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getCuentaCorriente(){
    const url: string = Global.url + '/api/cliente/cuentaCorriente';

    // return this.httpClient.get(url, {
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // })

    return new Promise(async (res) => {
      this.httpClient.get(url, {
        headers: {
          'Content-Type': 'application/json'
        },
      }).subscribe(data => {
        res(data);
      });
    });
  }

  getInvoicesByMovement(id: number | string, verMiFactura?:boolean){
    const url: string = Global.url + `/api/sa/caja/comprobantes`

    return this.httpClient.post(url, {id,verMiFactura}, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getServicesByMovement(orderId: string | number, category: string){
    const url: string = Global.url + `/api/sa/caja/servicios-movimiento/${orderId}/${category}`;

    return this.httpClient.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  //Nuevas funciones de caja

  getMovimientosCajaUsuario(uuidCuenta?:string,filtros?:any) {
    let url = Global.url + `/api/nocliente/caja/movimientos`;
    return new Promise(async (res) => {
      this.httpClient.post(url,{uuidCuenta,filtros}, {
        headers: {
          'Content-Type': 'application/json'
        },
      }).subscribe(data => {
        res(data);
      });
    });
  }

  getCantidadesMovimientos() {
    let url = Global.url + `/api/nocliente/caja/cantidades-movimientos`;
    return new Promise(async (res) => {
      this.httpClient.get(url, {
        headers: {
          'Content-Type': 'application/json'
        },
      }).subscribe(data => {
        res(data);
      });
    });
  }
}

