import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/app/global/Global';
import { log } from 'console';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

    constructor(private httpClient: HttpClient) { }

    addSubscription(sub: any) {
        let url: string = Global.url + '/api/sw/subscribe';

        return new Promise(async (res) => {
            this.httpClient.post(url, sub, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).subscribe(data => {
                res(data);
            });
        });
    }

    triggerNotify() {
        let url: string = Global.url + '/api/sw/trigger';

        return new Promise(async (res) => {
            this.httpClient.post(url, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).subscribe(data => {
                res(data);
            });
        });
    }

    getNotificaciones(query: any | undefined = undefined) {
        let url: string = Global.url + '/api/sw/notifs';
        

        return new Promise(async (res) => {
            this.httpClient.post(url, query ,{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).subscribe(data => {                
                res(data);
            });
        });
    }

    getNotificacionPrueba() {
        let url: string = Global.url + '/api/notificacionprueba';

        return new Promise(async (res) => {
          this.httpClient
            .get(
              url,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
            .subscribe((data) => {
              res(data);
            });
        });
    }

    setLeidas() {
        let url: string = Global.url + '/api/sw/leidas';

        return new Promise(async (res) => {
            this.httpClient.post(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).subscribe(data => {
                res(data);
            });
        });
    }

    setLeida(idNotif: number) {
        let url: string = Global.url + '/api/sw/leida';

        return new Promise(async (res) => {
            this.httpClient.post(url, {idNotif}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).subscribe(data => {
                res(data);
            });
        });
    }

    setVista() {
        let url: string = Global.url + '/api/sw/vista';

        return new Promise(async (res) => {
            this.httpClient.post(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).subscribe(data => {
                res(data);
            });
        });
    }

    verTodasLasNotificaciones() {
        let url: string = Global.url + '/api/sw/ver-todas-notificaciones';

        return new Promise(async (res) => {
            this.httpClient.post(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).subscribe(data => {
                res(data);
            });
        });
    }

    getPopup(){
        let url: string = Global.url + '/api/popups';

        return new Promise(async (res) => {
            this.httpClient.post(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).subscribe(data => {
                res(data);
            });
        });
    }

    setPopUpLeido(idPopup: number){
        let url: string = Global.url + '/api/popups/leido';

        return new Promise(async (res) => {
            this.httpClient.put(url, { idPopup },{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).subscribe(data => {
                res(data);
            });
        });
    }


    // PRUEBA
    // postNotifPrueb() {
    //     let url: string = Global.url + '/api/notificacionprueba';

    //     return new Promise(async (res) => {
    //         this.httpClient.get(url, {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         }).subscribe(data => {
    //             res(data);
    //         });
    //     });
    // }
}