<section class="results">

    <div class="results_container">
        <div class="results_header" *ngIf="results.listaPedidos" (click)="showPedidos = !showPedidos" matRipple>
            <mat-icon>{{showPedidos ? 'expand_less' : 'expand_more'}}</mat-icon>
            <h6>Pedidos ({{results.listaPedidos.length}} resultados)</h6>
        </div>
        <ul class="results_list" *ngIf="showPedidos">
            <a *ngFor="let pedido of results.listaPedidos" class="results_item" [href]="'/pedido/' + pedido.id"
                matRipple>
                <div class="id">
                    <p>AR -</p>
                    <p>#{{pedido.id}}</p>
                </div>

                <div class="ubicacion">
                    <p class="direccion">{{pedido.direccion}}</p>
                    <p class="localidad">{{pedido.localidad}}</p>
                </div>

                <div class="services">
                    <mat-icon *ngFor="let servicio of pedido.serviceIcon index as i;"
                        [class]="pedido.serviceStage[i] == '340' ? 'finalizado' : 'enProceso'">
                        {{servicio}}
                    </mat-icon>
                </div>

                <div class="results_item-info">
                    <div class="creadoPor">
                        <p>Creado por: {{pedido.creadorPedido}} - {{pedido.nombreSucursal}}</p>
                    </div>

                    <div class="sucursal">
                    </div>

                    <div class="recibePersona">
                        <p>Recibe: {{pedido.recibePersona}} - {{pedido.recibeContacto}}</p>
                    </div>
                </div>
            </a>
        </ul>

        <div class="results_header" *ngIf="results.listaPersonas" (click)="showPersonas = !showPersonas" matRipple>
            <mat-icon>{{showPersonas ? 'expand_less' : 'expand_more'}}</mat-icon>
            <h6>Personas ({{results.listaPersonas.length}} resultados)</h6>
        </div>

        <ul class="results_list" *ngIf="showPersonas">
            <a *ngFor="let persona of results.listaPersonas" class="results_item" matRipple>
                <img class="fotoPersona" [src]="persona.imagen ? persona.imagen : avatar" alt="fotoUsuario" width="50px" height="50px" />
                <div class="datosPersona">
                    <p>{{persona.nombreApellido}} - {{persona.rol}}</p>
                    <p>{{persona.email}} - {{persona.celular}}</p>
                </div>
            </a>
        </ul>

        <div class="results_header" *ngIf="results.listaMovimientos" (click)="showMovimientos = !showMovimientos" matRipple>
            <mat-icon>{{showMovimientos ? 'expand_less' : 'expand_more'}}</mat-icon>
            <h6>Movimientos ({{results.listaMovimientos.length}} resultados)</h6>
        </div>

        <ul class="results_list" *ngIf="showMovimientos">
            <a *ngFor="let movimiento of results.listaMovimientos" class="results_item" matRipple>
                <div class="id movID">
                    <p>Mov: {{movimiento.id}}</p>
                    <p *ngIf="movimiento.pedidoID">#{{movimiento.pedidoID}}</p>
                    <p *ngIf="movimiento.precompraID">#{{movimiento.precompraID}}</p>
                </div>

                <div class="fecha" *ngIf="getWidth()>600">
                    <p>{{formatearFecha(movimiento.fecha)}}</p>
                </div>

                <img [src]="movimiento.hechaPorImagen ? movimiento.hechaPorImagen : avatar " alt="fotoUsuario" class="fotoPersona"  />
                <div class="datosPersona movPer">
                    <p>{{movimiento.hechaPor}} - {{movimiento.hechaPorRol}}</p>
                    <p>{{movimiento.hechaPorEmail}} </p>
                    <p>{{movimiento.hechaPorCelular}}</p>
                </div>

                <div class="data">
                    <p>Tipo: {{getTipoMovimiento(movimiento)}}</p>
                    <p>Estado: {{getEstadoMovimiento(movimiento)}}</p>
                    <p>Monto: ${{getNumberWithCommas(movimiento.monto)}}</p>
                </div>

            </a>
        </ul>

    </div>

</section>