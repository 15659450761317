import { CommonModule } from '@angular/common';
import { Input, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

import { SearchBarComponent } from './search-bar.component';
import { ClientResultsModule } from './results/client-results/client-results.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { RelevadorResultsModule } from './results/relevador-results/relevador-results.module';
import { MatIconModule } from '@angular/material/icon';
import { DelegadoResultsModule } from './results/delegado-results/delegado-results.module';
import { EditorResultsModule } from './results/editor-results/editor-results.module';
import { SuperadminResultsModule } from './results/superadmin-results/superadmin-results.module';

@NgModule({
    declarations: [
        SearchBarComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        ClickOutsideModule,
        ClientResultsModule,
        RelevadorResultsModule,
        DelegadoResultsModule,
        EditorResultsModule,
        SuperadminResultsModule,
        MatIconModule
    ],

    exports:[
        SearchBarComponent
    ]
})
export class SearchBarModule
{
}