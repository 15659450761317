import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchResultsClient, SearchResultsMovimientos } from 'src/app/core/interfaces/SearchBar';
import { Utils } from 'src/app/global/Utils';
import { HostListener } from '@angular/core';
@Component({
  standalone: false,
  selector: 'delegado-results',
  templateUrl: './delegado-results.component.html',
  styleUrls: ['./delegado-results.component.scss']
})
export class DelegadoResultsComponent implements OnInit {


  showPedidos: boolean = false;
  showMovimientos: boolean = false;

  @Input() results: SearchResultsClient;

  constructor(private _router: Router) {  }

  ngOnInit(): void {    
  }

  formatearFecha(date: string) {
    let fecha = new Date(date);
    let day = (fecha.getDay() + 1) < 10 ? "0" + (fecha.getDay() + 1) : (fecha.getDay() + 1);
    let month = (fecha.getMonth() + 1) < 10 ? "0" + (fecha.getMonth() + 1) : (fecha.getMonth() + 1);
    return `${day}/${month}/${fecha.getFullYear()}`
  }

  getEstadoMovimiento(mov: SearchResultsMovimientos) {
    if (mov.estado == 0) {
      return "Impaga";
    } else if (mov.estado == 1) {
      return "Pagada";
    } else {
      return "Pendiente"
    }
  }

  getNumberWithCommas(number: any) {
    return Utils.numberWithCommas(number);
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    alert('Back button pressed');
  }
}
