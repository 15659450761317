import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class NotificationsService {
  private _cerrarNotificaciones = new BehaviorSubject<boolean>(false);

  cerrarNotificaciones = this._cerrarNotificaciones.asObservable();
  
  cerrarNotificacionesTopbar() {
      this._cerrarNotificaciones.next(true)
  }

  abrirNotificacionesTopbar(){
    this._cerrarNotificaciones.next(false)
  }
}