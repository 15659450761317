<div class="contenedorExterno" *ngIf="!cargando">

    <div class="contenedorInterno">

        <div [ngClass]="verificarUsuario()?'seccionIzqCliente':'seccionIzq'">
            <div class="grupo_logo_searchBar">
                <logo tipo="chico" (click)="irInicio()"></logo>
                <mat-icon class="logo_img" svgIcon="logoE3R2025" (click)="irInicio()"></mat-icon>
                <search-bar></search-bar>                        
            </div>
            <button mat-raised-button class="crearPedido" [ngClass]="{'crearPedido-disabled':ocultarNuevoPedido}" *ngIf="verificarUsuario()" [disabled]="ocultarNuevoPedido" 
            [matTooltip]="mostrarToolTip?tooltipNuevoPedido:null"  (click)="crearNuevoPedido()">
                <mat-icon class="icono-crear-pedido">add</mat-icon>            
                <span class="texto-crear-pedido" >Nuevo pedido</span>
            </button>
        </div>

        <div class="seccionDer">
            <div class="seccionCentral">
                <ng-container *ngIf="tipoCuentaID != 1" #options>
                    <ng-container *ngTemplateOutlet="submenu"></ng-container>
                  </ng-container>
            </div>

            <div class="menuUsuario">                
                <chat-activity (openChat)="openChat($event)" [openOtherMenu]="this.notifications"></chat-activity>
                <notifications (openNotifications)="openNotifications($event)" [openOtherMenu]="this.chat" [notificaciones]="this.notificaciones">
                </notifications>
                <profile></profile>
            </div>

        </div>
    </div>
</div>

<ng-template #submenu>
    <sub-menu [subMenuSeleccionado]="modificarSubMenuSeleccionado" (indexChange)="menuTabChange($event)" [puedeNavegar]="puedeNavegar" #submenucomp></sub-menu>
</ng-template>


<script src="/socket.io/socket.io.min.js"></script>