<section>

  <button class="boton-imagen" (click)="!cardOpen ? abrirPerfil() : cerrarPerfil()">
    <div class="imagen-fondo"></div>
    <img [src]="perfil.imagen || avatar" class="imagen">
    <mat-icon class="boton-icono">keyboard_arrow_down</mat-icon>

  </button>

  <mat-card class="contenedor-perfil" [ngClass]="{'animacion-abriendo': animacion == 'fadeIn', 'animacion-cerrando': animacion == 'fadeOut'}" *ngIf="cardOpen">
    <div class="contenedor-opciones">
      <div class="opcion" (click)="irA('/mi-perfil')">
        <mat-icon>account_circle</mat-icon>
        <span>Mi perfil</span>
      </div>

      <div class="opcion" (click)="irA('/guias-y-tips')">
        <mat-icon>help_outline</mat-icon>
        <span>Guias y Tips</span>
      </div>

      <div class="opcion" (click)="cerrarSesion()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Cerrar sesión</span>
      </div>
    </div>
  </mat-card>

</section>