import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PermisosService {
  private permisos: string = '';

  constructor() {
    this.actualizarPermisos();
  }

  actualizarPermisos(): void {
    const permisosGuardados = localStorage.getItem('pha');
    this.permisos = this.hexToBin(permisosGuardados);
  }

  tienePermiso(nombrePermiso: string): boolean {
    const indice = this.obtenerIndicePorNombre(nombrePermiso);
    return this.permisos.charAt(indice) === '1';
  }

  tieneAlgunPermiso(nombrePermisos: string[]): boolean {
    let tienePermiso = false;
    nombrePermisos.forEach(nombrePermiso=>{
      if(this.tienePermiso(nombrePermiso))
        tienePermiso = true;      
    });
    return tienePermiso;
  }

  private hexToBin(hex: string): string {
    // Función para convertir de hexadecimal a binario
    return (parseInt(hex, 16).toString(2)).padStart(29, '0');
  }

  private obtenerIndicePorNombre(nombrePermiso: string): number {
    //Estos permisos tienen que ser los mismos que se encuentran en la interface Permisos ubicada en E3R-BackEnd>src>core>interface>permisos.interface.ts
    const mapaDePermisos: string[] = [
      'crearEditarPedidos',
      'accederDetallesPedido',
      'usarChatPedido',
      'cargarDescargarMaterialPedido',
      'accederCajaClientes',
      'verDetallesMovimientosClientes',
      'aprobarAnularPagosClientes',
      'verDocumentosLegalesClientes',
      'accederCajaUsuarios',
      'verDetallesMovimientosUsuarios',
      'verFacturasUsuarios',
      'abonarRechazarSolicitudPagoUsuarios',
      'verComprobantesUsuarios',
      'subirComprobantesUsuarios',
      'gestionarUsuarios',
      'gestionarUsuariosRelevador',
      'gestionarUsuariosEditor',
      'gestionarUsuariosDelegado',
      'gestionarUsuariosAdmin',
      'gestionarMiembros',
      'asignarPermisos',
      'gestionarServicios',
      'gestionarCategoriasHabilidades',
      'gestionarNotificaciones',
      'accederAlmacenamiento',
      'accederFinanzas',
      'accederGenerales',
      'gestionarPedidos',
      'gestionarGuia'      
    ];
    const indice = mapaDePermisos.indexOf(nombrePermiso);
    return (indice == 0 || indice)? indice : -1;
  }
}