import { Injectable, EventEmitter } from "@angular/core";


@Injectable({
    providedIn: 'root',
})

export class DatosPerfilService {
    fotoPerfilChanged: EventEmitter<string> = new EventEmitter<string>();
    fotoMiembroChanged: EventEmitter<any> = new EventEmitter<any>();  
    datosPerfilChanged: EventEmitter<any> = new EventEmitter<any>(); 
    constructor(){}


    setFotoPerfil(fotoUrl: string){
        this.fotoPerfilChanged.emit(fotoUrl); 
    }

    setFotoMiembro(fotoUrl: string, indice: number){
        let datos = {
            fotoUrl: fotoUrl,
            indice: indice
        }
        this.fotoMiembroChanged.emit(datos); 
    }


    setNombreApellido(nombreApellido: any){
        this.datosPerfilChanged.emit(nombreApellido); 
    }

}