import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { ClientResultsComponent } from "./client-results.component";

@NgModule({
    declarations: [
        ClientResultsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatRippleModule
    ],
    exports: [
        ClientResultsComponent
    ]
}) export class ClientResultsModule { }