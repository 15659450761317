<h1 mat-dialog-title>Atención</h1>

<mat-dialog-content class="mat-typography">
    {{datos.mensaje}}
</mat-dialog-content>

<mat-dialog-actions class="acciones">
  <button mat-raised-button mat-button class="btn-red" (click)="onNoClick()">{{datos.botonNo?datos.botonNo: "No"}}</button>
  <button mat-raised-button mat-button class="green"  cdkFocusInitial (click)="onClick()">{{datos.botonSi?datos.botonSi: "Si"}}</button>
    
</mat-dialog-actions>
