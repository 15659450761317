import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'src/app/global/Global';
import { submenues } from './menus-templates/submenues';
import { GlobalService } from '../../core/services/global.service';
import { tap } from 'rxjs/operators';
import { SubmenuService } from '../../core/services/submenu.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmarPopupComponent } from 'src/app/global/confirmar-popup/confirmar-popup.component';
import { PermisosService } from 'src/app/core/services/permisos.service';
import { Subscription } from 'rxjs';
import { RoutingServiceSuperAdmin } from 'src/app/modules/superadmin/routing-sa/services/routing.service';
import { TopBarService } from '../Navegacion/topbar/services/topbar.service';
import { menus } from '../Navegacion/leftbar/menus-templates/menus';
import { TipoCuenta } from '../Navegacion/leftbar/menus-templates/menus.inteface';
import { Utils } from 'src/app/global/Utils';

export type TiposSubmenu = 'cliente'|'relevador'|'delegado'|'editor'|'sa';

export interface SubmenuChange {
  optionIndex: number,
  optionName: string,
  route: string,
  subscreen?: number,
  detallePedido?:boolean;
}

@Component({
standalone: false,
  selector: 'sub-menu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
})
export class SubmenuComponent implements OnInit {

  @Input() badges;
  @Input() puedeNavegar:boolean = true;
  @Input() subMenuSeleccionado = 0
  
  @Output() indexChange = new EventEmitter();
  
  rolUsuario: TiposSubmenu;
  indice: number = 0;
  icons: string[];
  links: string[];
  linksDiabled: boolean[] = [];
  rutaDefault: string;
  activeLink;
  anchoVentana = window.innerWidth;
  suscripciones = new Subscription

  menu

  /*permisosSA: string;*/

  constructor(private router: Router,
    private _global: GlobalService,
    private submenu: SubmenuService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private _permisos: PermisosService,
    private _routingServiceSuperAdmin: RoutingServiceSuperAdmin,
    private _topbarService:TopBarService
    ) {
  }

  ngOnChanges(changes: SimpleChanges) {
      this.inicializarMenu();
  }

  ngOnInit(): void {
    
    this.menu =  menus[Utils.obtenerTipoCuenta()]
    
    this.suscripciones.add(this._routingServiceSuperAdmin.opcion.subscribe((opcion:any) => {
      this.cambiarOpcion(opcion, this.links[opcion])
    }))
    
    this.suscripciones.add(this._topbarService.opcion.subscribe((opcion:number)=>{
      if(!opcion){
        this.activeLink = null
      }
    }))

    this.inicializarMenu();
    
    this.submenu.currentScreen.subscribe(value => {
      if(value !== null && value?.optionIndex !== undefined){
        this.indice = value.optionIndex
        this.activeLink = value.optionName;
      } else {
        this.indice = 0;
      }      
    })

    this.submenu.badges.subscribe(value => {
      this.badges = value;
      this.cdr.markForCheck();
    })    


      const idChat = localStorage.getItem('chat')
      if(idChat){
        if(Global.tipoCuenta == 'relevador' || Global.tipoCuenta == 'cliente'){
          this.indice = 1
          this.activeLink = 'Pedidos'
          this.cambiarOpcion(1,'Pedidos')
          this.event(1,'Pedidos')
        }else if(Global.tipoCuenta == 'delegado'){
          this.indice = 1
          this.activeLink = 'Gestion'
          this.cambiarOpcion(1,'Gestion')
          this.event(1,'Gestion')
        }else if (Global.tipoCuenta = 'editor'){
          this.indice = 0
          this.activeLink = 'Ediciones'
          this.cambiarOpcion(0,'Ediciones')
          this.event(0,'Ediciones')
        }
      }
  }



  async inicializarMenu(){   
        this._global.getDatosCuenta()
        .pipe(
          tap((data: any) =>{
            this.rolUsuario = this.asignarRol(data.data?.rol);   
            
            /*if(data.data.rol === 'super admin')
              this.permisosSA = this.hexToBin(data.data.permisos);*/
            
          }
          )
        )
        .subscribe((_) => {  
          
          this.icons = submenues[this.rolUsuario].icons;
          this.badges ??= submenues[this.rolUsuario].badges;  

          const optionIndexLastNavigation = JSON.parse(localStorage.getItem('lastNavigation'))?.optionIndex
          
          if(this.rolUsuario == 'sa')
            this.aplicarPermisos();
          this.links = submenues[this.rolUsuario].links;   
          this.rutaDefault = (submenues[this.rolUsuario].ruta)
          let url = window.location.href.split("/")[3];
          if(url.includes("mi-perfil") || url.includes('guias-y-tips')){
            this.activeLink = 'none';
          } else {
            this.activeLink = (this.rutaDefault === (this.router.url) || this.router.url.split('/')[1] === 'pedido') ? this.links[this.indice] : this.links.length>0? this.links[optionIndexLastNavigation || 0]: "none";
          }

          if (this.rolUsuario == 'sa') {
            this.links = this.links.filter(x => true);
            this.icons = this.icons.filter((x, index) => true);
          }

          if(this.subMenuSeleccionado == -1){
            this.activeLink = null
          }
        })

        if(this.rolUsuario==='relevador'){          
          this.puedeNavegar = false;
          const datos: any = await this._global.verificarDatos();
          this.puedeNavegar = datos.puedeTrabajar;
        }

       

  }

  asignarRol(rolUsuario: TiposSubmenu){
    /*if(Global.superadmin){*/
    /* Deprecamos variable superadmin */
    if(Global.tipoCuentaID == 999){
      return this.rolUsuario = 'sa';
    }
    const rol = rolUsuario[0].toUpperCase() + rolUsuario.slice(1).toLowerCase();
    switch(rol){
      case 'Editor':
        return this.rolUsuario = 'editor';
      case 'Delegado':
        return this.rolUsuario = 'delegado';
      case 'Relevador':
        return this.rolUsuario = 'relevador';
      default:
        return this.rolUsuario = 'cliente'
    }
   
  }

  cambiarOpcion(opcDestino: number, link: any) {


    const subioArchivos = Number(localStorage.getItem('subioArchivo'))
    
    if(subioArchivos == 1){
      const dialog = this.dialog.open(ConfirmarPopupComponent,{
        height:'auto',
        width:'auto',
        data:{mensaje:'Todavia no finalizaste la subida de archivos, ¿Estas seguro que queres salir?'}
      })

      dialog.afterClosed().pipe().subscribe(opcion => {
        if (opcion == "SI") {
          localStorage.setItem('subioArchivo','0')
          if(this.puedeNavegar) {
            this.subMenuSeleccionado = 0
            if(this.badges?.[link]) this.badges[link] = 0
            this.submenu.changeCurrentScreen({optionIndex: opcDestino, optionName: link, route: this.convertRolToRoute()});
          } else {
            if(this.rolUsuario === 'relevador'){
              this.submenu.changeCurrentScreen({optionIndex: opcDestino, optionName: link, route: 'relevador'});
            }      
          }
        }
      })
    }else{
      if(this.puedeNavegar) {
        this.subMenuSeleccionado = 0

        if(this.badges?.[link]){
          this.badges[link] = 0
        }

        let ruta = this.convertRolToRoute()

        if(this.menu?.opciones[opcDestino]?.ruta){
          ruta = this.menu.opciones[opcDestino].ruta
        }

        this.submenu.changeCurrentScreen({optionIndex: opcDestino, optionName: link, route: ruta});
        this.redireccionar(this.menu.opciones[opcDestino].ruta || this.badges.ruta)
        // this.router.navigate(['/'])
      } else {
        if(this.rolUsuario==='relevador'){
          this.submenu.changeCurrentScreen({optionIndex: opcDestino, optionName: link, route: this.menu?.opciones[opcDestino]?.ruta? this.menu.opciones[opcDestino].ruta: 'relevador'});
        }      
      }
    }
  }

  redireccionar(ruta:string){
    this.router.navigateByUrl(ruta)
  }

  convertRolToRoute(): string{
    const route = this.rolUsuario === 'cliente' ? 'home' : this.rolUsuario === 'sa' ? 'superadmin': this.rolUsuario;
    // this.indexChange.emit({ indice: this.indice, ruta });
    return route;
  }

  event(i: number, link: string) {
    if(this.puedeNavegar) {
      this.indexChange.emit({ i, link });
    }
  }

  cambiaropc(){
    this.cambiarOpcion(2,"Ofertas");
    this.event(2,"Ofertas");
  }

  private hexToBin(hex: string): string {
    // Función para convertir de hexadecimal a binario
    return (parseInt(hex, 16).toString(2)).padStart(29, '0');
  }  

  private aplicarPermisos(){
    if(this.rolUsuario == 'sa'){
      this.linksDiabled = []
      for (let i = 0; i <= submenues[this.rolUsuario].links.length; i++) {
        this.linksDiabled.push(false)
      }
      this.linksDiabled[submenues[this.rolUsuario].links.indexOf('Configuración')] = !this._permisos.tieneAlgunPermiso(['gestionarServicios', 'gestionarCategoriasHabilidades', 'gestionarNotificaciones', 'accederAlmacenamiento', 'accederFinanzas', 'accederGenerales', 'gestionarPedidos', 'gestionarGuia' ]);
      this.linksDiabled[submenues[this.rolUsuario].links.indexOf('Caja')] = !this._permisos.tieneAlgunPermiso(['accederCajaUsuarios', 'accederCajaClientes']);
      this.linksDiabled[submenues[this.rolUsuario].links.indexOf('Clientes')] = !this._permisos.tienePermiso('gestionarMiembros');
      this.linksDiabled[submenues[this.rolUsuario].links.indexOf('Usuarios')] = !this._permisos.tienePermiso('gestionarUsuarios');
    }
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.anchoVentana = window.innerWidth;
  }

  mostrarTooltip() {
    return this.anchoVentana <= 1190;
  }

  ngOnDestroy(){
    this.suscripciones.unsubscribe()
  }

}