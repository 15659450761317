import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class TopBarService {

    private _nuevoPedido: Subject<void> = new Subject<void>();
    private _inicio: Subject<void> = new Subject<void>();
    private _opcion = new BehaviorSubject<any>(0);

    opcion = this._opcion.asObservable()

    realizarNuevoPedido() {      
        this._nuevoPedido.next();  
    }

    cambiarOpcion(opc:any){
        this._opcion.next(opc)
    }

    volverAInicio(){
        this._inicio.next()
    }

    get nuevoPedido(){
        return this._nuevoPedido.asObservable();
    }

    get inicio(){  
        return this._inicio.asObservable();
    }
}