import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { DelegadoResultsComponent } from "./delegado-results.component";


@NgModule({
    declarations: [
        DelegadoResultsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatRippleModule
    ],
    exports: [
        DelegadoResultsComponent
    ]
}) export class DelegadoResultsModule { }