import { RoutingServiceSuperAdmin } from './../../../modules/superadmin/routing-sa/services/routing.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Router } from '@angular/router';
import { ServerStatus } from 'src/app/core/enums/ServerStatus';
import Swal from 'sweetalert2';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { ChatTopbarService } from '../../controls/chat-activity/services/chat.service';
import { Subscription } from 'rxjs';
import { ChatDesplegableService } from 'src/app/global/chat-desplegable/services/chatDesplegable.service';
import { NotificationsService } from './services/notifications.service';

@Component({
standalone: false,
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [
    trigger('cerrarAnimacion', [
      state('visible', style({
        opacity: 1
      })),
      state('oculto', style({
        opacity: 0
      })),
      transition('visible <=> oculto', [
        animate('0.15s')
      ])
    ])
  ]
})
export class NotificationsComponent implements OnInit {

  readonly VAPID_PUBLIC_KEY = "BIrJ4Jm-kFv1TzSLJcXh2lvzNyE9PwjdRo5AQe6sA7WEDOn-dxvCJw7GRwC4SHDbLnmJvo7NN5PV8NlPz46aEpE";
  suscribing: boolean = false

  amountOfNotifications: number = 10;
  offsetNotifications: number = 0;

  // notificaciones: any[] = [];
  updatingNotif: boolean = false;
  notSinLeer: number = 0;
  notSinVer: number = 0
  totalNotificaciones: number = 0;

  isLoadingMore: boolean = false;

  claseFiltroTodos: string = 'activo';
  claseFiltroNoLeido: string = 'no-activo';

  cardOpen = false;
  animacion: 'fadeIn' | 'fadeOut' | '' = '';

  abrirOpciones = false

  notificacionesCargadas = false

  private clickOutsideListener: () => void;

  @Input() nuevaNotificacion: {};
  @Input() openOtherMenu: boolean = false;
  @Output() openNotifications = new EventEmitter<boolean>()
  @Input() notificaciones: any[] = [];

  private _suscripciones = new Subscription();

  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    // private snackBar: MatSnackBar,
    private notificationService: NotificationService,
    private elementRef: ElementRef,
    private routingServiceSuperAdmin: RoutingServiceSuperAdmin,

    private _chatDesplegableService: ChatDesplegableService,
    private _chatTopbarService: ChatTopbarService,
    private _notificationService: NotificationsService
  ) {
    this.swPush.messages.subscribe((msg: any) => {
      this.traerNotificaciones(1);
      Swal.fire({
        title: msg.notification.title,
        text: msg.notification.body,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Confirmar'
      })
    })

    this.swUpdate.versionUpdates.subscribe(event => {
      if (event.type === 'VERSION_READY') {
        // const snack = this.snackBar.open('Actualización Disponible', 'Recargar')
        // snack.onAction().subscribe(() => {
        //   window.location.reload()
        // })
      }
    })

    this.swPush.subscription.subscribe((sub: any) => {
      if (!sub && !this.suscribing) {
        this.suscribing = true
        this.swPush.requestSubscription({
          serverPublicKey: this.VAPID_PUBLIC_KEY
        })
          .then((sub: any) => this.notificationService.addSubscription(sub).then((res: any) => {
            if (res.status == ServerStatus.SUCCESSFUL) {

            } else {

            }
            this.suscribing = false
          }))
          .catch((err: any) => {
            console.error("Could not subscribe to notifications", err)
            this.suscribing = false
          });
      }
    })
  }

  ngOnInit(): void {
    this._suscripciones.add(this._notificationService.cerrarNotificaciones.subscribe((cerrarNotificaciones:any) => {
      if(cerrarNotificaciones){
        this.cerrarNotificaciones()
      }
    }))

    this.clickOutsideListener = this.onClickFuera.bind(this);
    document.addEventListener('click', this.clickOutsideListener);
    this.traerNotificaciones(1);
  }

  openNotification(state: boolean) {
    this.openNotifications.emit(state);
    this.notificationService.setLeidas().then((res: any) => {
      if (res.status == ServerStatus.SUCCESSFUL) {
        this.notSinLeer = 0;
        this.notificaciones = [...this.notificaciones];
        this.traerNotificaciones(1);
      }
    })

  }

  leerNotif(idNotif: number, indice: number) {
    this.updatingNotif = true
    this.notificationService.setLeida(idNotif).then((res: any) => {
      if (res.status == ServerStatus.SUCCESSFUL) {
        this.notificaciones[indice].leido = true
        this.notificaciones[indice].class = "leido"
        this.notSinLeer--
        this.updatingNotif = false
      }
    })
  }


  traerNotificaciones(leido: number) {
    this.notificacionesCargadas = false
    this.isLoadingMore = true;
    const query = {
      offset: this.offsetNotifications,
      amount: this.amountOfNotifications
    }

    this.notificationService.getNotificaciones(query).then((notifObject: any) => {
      this.totalNotificaciones = notifObject.totalAmount;
      this.notSinVer = notifObject.unreadAmount
      notifObject.notifs.forEach((not: any) => {
        not.fecha = this.obtenerTiempoTranscurrido(not.fecha);

        if (not.leido == 0) {
          this.notSinLeer++;
          not.class = "sinLeer";
          this.notificaciones.push({ ...not });

        } else if (leido === 1) {
          not.class = "leido";
          this.notificaciones.push({ ...not });
        }
      })

      this.isLoadingMore = false;
      this.notificacionesCargadas = true
    })
  }

  obtenerTiempoTranscurrido(fecha: any): string {
    const fechaActual = new Date().getTime(),
      fechaNotificacion = new Date(fecha).getTime(),
      diferenciaEntreFechas = fechaActual - fechaNotificacion,
      diferenciaEnSegundos = Math.round(diferenciaEntreFechas / 1000);

    const unidadesTiempo = [
      { valor: 60, unidad: 'segundos' },
      { valor: 60, unidad: 'minutos' },
      { valor: 24, unidad: 'horas' },
      { valor: 7, unidad: 'días' },
      { valor: 4, unidad: 'semanas' },
      { valor: 1, unidad: 'meses' }
    ];

    let tiempo = diferenciaEnSegundos;
    let index = 0;

    for (index; index < unidadesTiempo.length; index++) {
      if (tiempo < unidadesTiempo[index].valor) {
        break;
      }
      tiempo = Math.round(tiempo / unidadesTiempo[index].valor);
    }

    let unidad = unidadesTiempo[index]?.unidad;
    if (tiempo === 1) {
      if (unidad === "meses") {
        unidad = unidad?.slice(0, unidad.length - 2);
      } else {
        unidad = unidad?.slice(0, unidad.length - 1);
      }
    }
    return `hace ${tiempo} ${unidad}`;

  }


  onScrollCheck(event: any) {
    if (this.totalNotificaciones > this.notificaciones.length) {
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        this.offsetNotifications++;

        this.traerNotificaciones(1);
      }
    }
  }

  redirigir(ruta: string) {
    this.router.navigate([`/${ruta}`]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['nuevaNotificacion']) {

      if (Object.keys(this.nuevaNotificacion).length !== 0) {
        this.notificaciones = [];
        this.notSinLeer = 0;
        this.amountOfNotifications++;

        this.traerNotificaciones(1);

        this.reproducirAlertaNuevaNotificacion();
      }
    }

    if (changes['openOtherMenu']) {
      if (this.openOtherMenu) {
        this.openNotification(!this.openOtherMenu);
      }
    }
  }

  mostrarTodas() {
    this.claseFiltroTodos = 'activo';
    this.claseFiltroNoLeido = 'no-activo';
    this.notificaciones = [];
    this.notSinLeer = 0;

    this.traerNotificaciones(1);
  }

  mostrarNoLeidas() {
    this.claseFiltroNoLeido = 'activo';
    this.claseFiltroTodos = 'no-activo';
    this.notificaciones = [];
    this.notSinLeer = 0;

    this.traerNotificaciones(0);
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.clickOutsideListener);
    this._suscripciones.unsubscribe()
  }

  abrirNotificaciones() {
    this._chatTopbarService.cerrarChat()
    this._chatDesplegableService.cerrarChatDesplegable()
    this._notificationService.abrirNotificacionesTopbar()

    this.animacion = 'fadeIn';
    this.cardOpen = !this.cardOpen;
    this.notificationService.setVista().then((res:any) => {
      if(res.status == 200){
        this.notSinVer = 0
      }
    })
  }

  cerrarNotificaciones() {
    this.animacion = 'fadeOut'
    this.cardOpen = false
  }

  private onClickFuera(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      const elemento = event.target as HTMLElement
      if(elemento.classList[0] != 'cdk-overlay-backdrop' && elemento.classList[1] != 'boton-accion'){
        this.cerrarNotificaciones();
      }
    }
  }

  irA(ruta: string) {
    if(ruta == 'usuario'){
      this.routingServiceSuperAdmin.cambiarOpcion(3)
      this.router.navigate([('/')]);
      return
    }else if(ruta == 'cliente'){
      this.routingServiceSuperAdmin.cambiarOpcion(2)
      this.router.navigate([('/')]);
      return  
    }

    if(ruta.split('_')[0] == 'chat'){
      localStorage.setItem('chat',ruta.split('_')[1])
      // location.replace('/')
      this.cerrarNotificaciones()
      this._chatTopbarService.abrirChat(Number(ruta.split('_')[1]))
      return
    }else{
      this.router.navigate([('/' + ruta)]);
    }


  }

  verTodasLasNotificaciones(){
    this.notificationService.verTodasLasNotificaciones().then((res:any) => {
      if(res.status == 200){
        for (let i = 0; i < this.notificaciones.length; i++) {
          this.notificaciones[i].visto = 1
          this.notificaciones[i].leido = 1
        }
      }
    })
  }

  reproducirAlertaNuevaNotificacion(){
    const audio = new Audio();
    audio.src = '../../../../assets/sounds/alerta-nueva-notificacion.wav'
    audio.load();
    audio.play();
  }
}
