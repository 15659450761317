export const menus = {
    editor: {
        ruta: '/editor',
        opciones: [
            {tooltip: "Ediciones", icono: "edit"},
            {tooltip: "Caja", ruta:'/caja/editor', icono: "local_atm"}
        ],
        badges: {},
        indiceInicial: 0,
    },
    relevador: {
        ruta: '/relevador',
        opciones: [
            {tooltip: "Inicio", icono: "home"},
            {tooltip: "Pedidos", icono: "toc"},
            {tooltip: "Mis Horarios", icono: "calendar_today"},
            {tooltip: "Ofertas", icono: "sell"},
            {tooltip: "Caja", ruta:'/caja/relevador', icono: "local_atm"}
        ],
        badges: {
            'Ofertas': 0,
            'Entrega Material': 0
        },
        indiceInicial: 0,
    },
    delegado: {
        ruta: '/delegado',
        opciones: [
            {tooltip: "Ofertas", ruta:'/delegado/ofertas', icono: "content_paste"},
            {tooltip: "Gestion", ruta:'/delegado/gestion', icono: "drive_file_rename_outline"},
            {tooltip: "Ediciones", ruta:'/delegado/ediciones', icono: "drive_file_rename_outline"},
            {tooltip: "Caja", ruta:'/caja/delegado', icono: "local_atm"}
        ],
        badges: {},
        indiceInicial: 0,
    },
    sa: {
        ruta: '/superadmin',
        opciones: [
            {tooltip: "Inicio", icono: "home"},
            {tooltip: "Agenda", icono: "calendar_today"},
            {tooltip: "Clientes", icono: "work"},
            {tooltip: "Usuarios", icono: "person"},
            {tooltip: "Caja", ruta:'/caja', icono: "local_atm"},
            {tooltip: "Configuración", icono: "settings"}
        ],
        badges: {},
        indiceInicial: 0,
    },
    cliente: {
        ruta: '/home',
        opciones: [
            {tooltip: "Inicio", icono: "home", submenu:[], opcion:0},
            {tooltip: "Agenda", icono: "calendar", submenu:[], opcion:1},
            {tooltip: "Pedidos", icono: "components", opcion:2, 
            submenu:[
                {titulo: "Pendientes" , icono:"history-toggle"},
                {titulo: "En edición" , icono:"file-pencil"},
                {titulo: "Finalizados" , icono:"browser-check"},
                {titulo: "Cancelados" , icono:"folder-off"},
                {titulo: "Todos" , icono:"list-details"},
            ]},
            {tooltip: "Caja", icono: "pig-money", opcion:3,
            submenu:[
                {titulo: "Movimientos", ruta:'/caja/cliente', icono:"list-details"},
                {titulo: "Pagos en proceso", ruta:'/caja/cliente/gestion-pagos' , icono:"money-symbol"},
                {titulo: "Cargar saldo", icono:"credit-card-refund"},
                {titulo: "Precompras disponibles", ruta:'/caja/cliente/mis-precompras' , icono:"apps"},
                // {titulo: "Transferir" , icono:"transfer"},
                // {titulo: "Facturación" , icono:"file-dollar"},
            ]},
            {tooltip: "Shop", ruta:'/comprar/precompras', icono: "shopping-cart", opcion:4,submenu:[]},
            {tooltip: "Mi empresa", icono: "building-company", opcion:5, 
            submenu:[
                {titulo: "Datos" , icono:"edit"},
                {titulo: "Sucursales" , icono:"building-skyscraper"},
                {titulo: "Teams" , icono:"users-group"},
                {titulo: "Miembros" , icono:"users"}
            ]},
            {tooltip: "Configuración", icono: "settings", opcion:6,submenu:[
                // {titulo: "Marca de agua", icono:"branding_watermark"},
                {titulo: "Fotos" , icono:"camera_alt"},
                {titulo: "Videos" , icono:"videocam"},
                {titulo: "Planos" , icono:"straighten"}
            ]}
        ],
        badges: {},
        indiceInicial: 0,
    }
};


