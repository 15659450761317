import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CancelDialogService } from 'src/app/core/services/cancel-dialog.service';
import { DatosEmpresaService } from 'src/app/core/services/datosEmpresa.service';
import { SubmenuService } from '../../../core/services/submenu.service';
import { DatosPerfilService } from 'src/app/core/services/datosPerfil.service';
import { ConfirmarPopupComponent } from 'src/app/global/confirmar-popup/confirmar-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { Global } from 'src/app/global/Global';
import { Recursos } from 'src/app/global/Recursos';
import { LeftBarService } from '../../Navegacion/leftbar/services/leftbar.service';
import { Utils } from 'src/app/global/Utils'

interface Perfil {
  uuid: string
  nombre: string,
  apellido: string,
  email: string,
  imagen: string
}

@Component({
standalone: false,
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  perfil: Perfil = {
    uuid: "",
    nombre: "",
    apellido: "",
    email: "",
    imagen: ""
  };

  imagenResponsable = Recursos.imagenAvatar;

  cardOpen = false;
  animacion: 'fadeIn' | 'fadeOut' | '' = '';

  avatar = Recursos.imagenAvatar;

  private clickOutsideListener: () => void;

  constructor(
    private _datosEmpresaService: DatosEmpresaService,
    private _activador: CancelDialogService,
    private router: Router,
    private submenu: SubmenuService,
    private elementRef: ElementRef,
    private _datosPerfil: DatosPerfilService,
    public dialog: MatDialog,
    private _leftBarService: LeftBarService
  ) {
    this._activador.actualizarFotoPerfil.subscribe(
      () => {
        this.traerDatos()
      }
    )
  }

  ngOnInit(): void {
    this.traerDatos();
    this.clickOutsideListener = this.onClickFuera.bind(this);
    document.addEventListener('click', this.clickOutsideListener);
  }

  traerDatos() {
    this._datosEmpresaService.getDatosCuenta().subscribe((datos: any) => {
      Global.nombre = datos.nombre;
      Global.apellido = datos.apellido;
      if (!datos.imagen || datos.imagen == "") {
        datos.imagen = this.imagenResponsable
      } else {
        datos.imagen = Utils.getRutaPublicaMiniatura(datos.imagen)
      }
      this.perfil = { ...datos }
    })

    this._datosPerfil.fotoPerfilChanged.subscribe((fotoUrl: string) => {
      if (!fotoUrl || fotoUrl == "") {
        this.perfil.imagen = this.imagenResponsable;
      }
      else {
        this.perfil.imagen = fotoUrl; 
      }
    })
  }

  /* Coloco un async en la funcion para poder ponerle un await a la redireccion a la ruta,
   ya que sin el await se ejecuta window.location.reload(); sin que se haya redireccionado a la ruta 
   y esto ocasiona problemas con los guards*/
  async cerrarSesion() {
    //Remueve el token del localStorage --> ok
    localStorage.removeItem('token');
    //si hay permisos de administrador los remueve
    localStorage.removeItem('pha');

    //Remueve el token del sessionStorage --> No parece usarse
    sessionStorage.clear();

    await this.router.navigate(['/auth']);

    this.submenu.clearLastNavigation();
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.clickOutsideListener);
  }

  abrirPerfil() {
    this.animacion = 'fadeIn';
    this.cardOpen = !this.cardOpen;
  }

  cerrarPerfil() {
    this.animacion = 'fadeOut';
    this.cardOpen = false;
  }

  private onClickFuera(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.cerrarPerfil();
    }
  }

  irA(ruta: string) {
    const subioArchivos = Number(localStorage.getItem('subioArchivo'))
    if(subioArchivos == 1){
      const dialog = this.dialog.open(ConfirmarPopupComponent,{
        height:'auto',
        width:'auto',
        data:{mensaje:'Todavia no finalizaste la subida de archivos, ¿Estas seguro que queres salir?'}
      })
      dialog.afterClosed().pipe().subscribe(opcion => {
        if (opcion == "SI") {
          localStorage.setItem('subioArchivo','0') 
          this.router.navigate([ruta]);
        }
      })
    }else{
      if(Global.tipoCuentaID == 1){
        this._leftBarService.cambiarOpcion(0)
        this._leftBarService.cambiarSubOpcion(0)
      }
      this.router.navigate([ruta]);
    }
  }
}
