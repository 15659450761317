import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';

import { ClientMenuComponent } from './client-menu.component';

import {MatTabsModule} from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        ClientMenuComponent
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        MatDialogModule,
        MatIconModule
    ],
    exports:[
        ClientMenuComponent
    ]
})
export class ClientMenuModule
{
}