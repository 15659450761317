import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatActivityComponent } from './chat-activity.component';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { ClickOutsideModule } from 'ng-click-outside';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ChatSeleccionadoModule } from 'src/app/global/chat-desplegable/chat-seleccionado/chat-seleccionado.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChatDesplegableModule } from 'src/app/global/chat-desplegable/chat-desplegable.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';




@NgModule({
  declarations: [ChatActivityComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatBadgeModule,
    ClickOutsideModule,
    MatMenuModule,
    MatCardModule,
    MatButtonModule,
    ChatSeleccionadoModule,
    MatProgressSpinnerModule,
    MatSnackBarModule
  ],
  exports: [ChatActivityComponent]
})
export class ChatActivityModule { }
