import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Global } from "src/app/global/Global";

@Injectable({
    providedIn: 'root'
}) export class SearchBarService {

    constructor(private httpClient: HttpClient) { }


    public search(q: string): Observable<any> {
        q = q.replace(/[#]/gi, 'id:')
        q = q.replace(/[$]/gi, 'monto:')
        let url = Global.url + `/api/searchbar/${q}`
        return this.httpClient.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}