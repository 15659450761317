<section #seccion id="seccion" >

    <mat-icon class="icono-chat-topbar" [matBadge]="cantMensajesSinLeer" matBadgeSize="small" matBadgeColor="warn" [matBadgeHidden]="cantMensajesSinLeer <= 0" (click)="!cardOpen ? abrirMensajes() : cerrarMensajes()">forum</mat-icon>

    <div  id="asd" class="contenedor-chat" [ngClass]="{'animacion-abriendo':animacion == 'fadeIn', 'animacion-cerrando': animacion == 'fadeOut'}" *ngIf="cardOpen" #scrollableDiv>
        
        <div class="cabecera">
            <h1 class="titulo">Mensajes</h1>
            <button mat-icon-button *ngIf="!cargando && !idPedido && pedidoFiltrado" (click)="getChats()"><mat-icon>chevron_left</mat-icon></button>
        </div>

        <div class="cuerpo" *ngIf="!cargando && pedidoFiltrado">
            <div class="salas" *ngFor="let sala of participantes; index as i" [ngClass]="{'fila_pedido_impar': i % 2 == 0}" (click)="cambiarChat('seleccionado',sala)">
                <div class="userImg">
                    <img class="avatar" [src]="sala.imagen ? sala.imagen : avatar" alt="Avatar">
                    <p class="rol">{{sala.rol}}</p>
                </div>

                <div class="titulo-sala">
                    <div class="contenedor-texto">
                        <p class="titulo">{{sala.nombre + ' ' + sala.apellido}}</p>
                        <p class="mensaje {{(!sala.ultimoMensaje.horaVisto && sala.ultimoMensaje.mensaje && sala.ultimoMensaje.uuid !== uuidCuenta)?'mensaje-novisto':''}}">{{sala.ultimoMensaje.mensaje}}</p>
                    </div>
                     <div class="circulo-notificacion" *ngIf="!sala.ultimoMensaje.horaVisto && sala.ultimoMensaje.mensaje && sala.ultimoMensaje.uuid !== uuidCuenta"></div>
                </div>

            </div>

            <div class="sin-salas" *ngIf="participantes?.length == 0 && chatsGrupales?.length == 0 && !cargando">
                <p class="mensaje-sin-salas">No se encontro ninguna sala {{idPedido?'para el pedido #' + idPedido:''}}</p>
            </div>

            <div class="salas" *ngFor="let grupo of chatsGrupales; index as i" (click)="cambiarChat('seleccionado',grupo)" [ngClass]="{'fila_pedido_impar': i % 2 == 0}">
                <div class="userImg">
                    <img class="avatar" [src]="avatar" alt="Avatar">
                </div>
                <div class="titulo-sala">
                    <div class="contenedor-texto">
                    <p class="titulo">Grupo de edicion {{i + 1}}</p>
                    <p class="ultimo-mensaje {{(!grupo.ultimoMensaje?.horaVisto && grupo.ultimoMensaje?.mensaje && grupo.ultimoMensaje?.uuid !== uuidCuenta)?'mensaje-novisto':''}}">{{grupo.ultimoMensaje?.mensaje}}</p>
                    </div>
                    <div class="circulo-notificacion" *ngIf="!grupo.ultimoMensaje?.horaVisto && grupo.ultimoMensaje?.mensaje && grupo.ultimoMensaje?.uuid !== uuidCuenta"></div>
                </div>
            </div>
        </div>

        <div class="cuerpo" *ngIf="!pedidoFiltrado">
            <div class="salas sin-filtrar" *ngFor="let sala of salaChatsParticipa; index as i" [ngClass]="{'fila_pedido_impar': i % 2 == 0}" (click)="cambiarChat('lobby',null); getChats(sala.id)">
                    <div class="titulo-pedido">
                        <p class="titulo">Pedido #</p>
                        <p class="pedido">{{sala.id}}</p>
                    </div>

                   <div class="circulo-notificacion" *ngIf="sala.notificacion"></div>
            </div>

            <div class="sin-salas" *ngIf="salaChatsParticipa.length == 0" >
                <p class="mensaje-sin-salas">No se encontro ningún pedido con chat</p>
            </div>

            <div *ngIf="superAdmin && tipoCuenta != 999" class="superadmin">

                <hr class="linea">

                <div class="salas fila_pedido_impar" (click)="cambiarChat('seleccionado',superAdmin)">
                    <div class="userImg">
                        <img class="avatar" [src]="superAdmin.imagen ? superAdmin.imagen : avatar" alt="Avatar">
                        <p class="rol">{{superAdmin.rol}}</p>
                    </div>
                    <div class="titulo-sala">
                        <p class="titulo">{{superAdmin.nombre + ' ' + superAdmin.apellido}}</p>
                        <p class="mensaje {{(!superAdmin.ultimoMensaje.horaVisto && superAdmin.ultimoMensaje.mensaje && superAdmin.ultimoMensaje.uuid !== uuidCuenta)?'mensaje-novisto':''}}">{{superAdmin.ultimoMensaje.mensaje}}</p>
                    </div>
    
                    <div class="circulo-notificacion" *ngIf="!superAdmin.ultimoMensaje.horaVisto && superAdmin.ultimoMensaje.mensaje && superAdmin.ultimoMensaje.uuid !== uuidCuenta"></div>
                </div>
            </div>

        </div>
        <div class="cargando" *ngIf="cargando">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</section>

<div class="chat-anclado">
        <chat-seleccionado 
        *ngIf="tipoChatAbierto == 'seleccionado'" 
        (eventoChatAbierto)="tipoChatAbierto = ''" 
        (chatSeleccionado)="cambiarChat('lobby', null)"  
        [pedido]="pedido"
        [chatGrupalSeleccionado]="chatGrupalSeleccionado"
        [participante]="chatSeleccionado"
        [participantes]="participantes"
        [salaChat]="salaChat"
        [salaUsuarioIniciado]="salaUsuarioIniciado"
        [participanteSuperadmin]="participanteSuperadmin"
        [esSuperAdmin]="esSuperAdmin"
        >
        </chat-seleccionado>
</div>
