.searchBar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .mdc-text-field--outlined {
    background-color: white;
    border-radius: 4px;
  }

  .mdc-text-field {
    padding-bottom: 0 !important;
    max-height: 35.5px !important;
    margin-top: 0 !important;
  }

  .mdc-text-field--outlined {
    top: 9px !important;
    max-height: 35.5px !important;
  }

.mat-mdc-text-field-wrapper {
    max-height: 35.5px !important;
    height: 35.5px !important;
    padding: 0 8px;
  }


 /*  .mdc-text-field:hover .mdc-notched-outline {
    border-color: transparent;
  }

  .mdc-text-field.mdc-text-field--focused .mdc-notched-outline {
    border-color: transparent;
    border-radius: 5px; 
  }


  
  .mdc-text-field__input {
    border-top: 0.6rem solid transparent;
    padding: 0; 
    padding: 0em 0px 1em 0px !important;
  }






  .search-container {
    flex: 1;              
    display: flex;
    justify-content: flex-end; 
    max-width: 400px;     
  }

  .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 6px !important;
  }
 */
/*   ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
    top: 0;
    max-height: 35.5px;
  } */

  /* ::ng-deep .mat-form-field-wrapper { */
  /*   max-height: 35.5px; */
  /*   margin-top: 0; */
  /* } */


 /*  ::ng-deep mat-form-field.mat-form-field:hover .mat-form-field-outline-thick {
    color: transparent;
  }

  ::ng-deep mat-form-field.mat-form-field.mat-focused .mat-form-field-outline-thick {
      color: transparent; 
      border-radius: 5px;
  }

  ::ng-deep .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  ::ng-deep .mat-form-field-infix {
    border-top: .6rem solid transparent;
  }

  mat-form-field {
    width: 100%;
    height: 100%;
  }

  ::ng-deep .mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: 0;
  }

  ::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0;
  }

  ::ng-deep .mat-form-field-flex>.mat-form-field-infix {
    padding: 0em 0px 1em 0px !important;
  } */


  button {
    padding: 0;
    margin: 0;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: -8px;
    /* border:1px solid red; */

    img {
      width: 20px;
      margin-bottom: 4px;
    }

    mat-icon {
      font-size: 24px !important;
      height: 23px !important;
      width: 23px !important;
      color: gray;
    }
  }

  .mdc-button {
      min-width: 0;

  }

  .searchField {
    width: 100%;
    position: relative; /* agregado para usar posicionamiento relativo */
  }

  mat-mdc-button.mat-blue {
    position: absolute;
    margin: 0 !important; padding: 0 !important;
    right: -9px;
    bottom:4px;
    height: 35px;
    min-width: 30px !important;
    width: 50px !important;
    background-color: rgba(255,255,255,0);
    color: #7F7F7F;

  }

  .open_search {
    display: none;
  }

}

.results {
  position: absolute;
  top: 1.4rem;
  z-index: 1000;
  width: 40%;
  height: 25rem;
}



@media only screen and (max-width: 590px) {

.searchBar {
  align-items: center;
  margin-top: -3px !important;
  .searchField {
    display: none;
  }

  .open_search {
    display: flex !important;
    justify-content: center;
    align-items: center;
  
    width: 36px !important;
    max-width: 36px !important;
    min-width: 36px !important;
  
    height: 36px !important;
    max-height: 36px !important;
    min-height: 36px !important;
  
    border-radius: 50%;

    line-height: 0 !important;
    margin: 0 !important;

    background-color: #ffffff;
    color: #7b7b7b;

    mat-icon {
      /* border:1px solid red; */
      border-radius: 100%;
      font-size: 30px !important;
      width: 30px !important;
      height: 30px !important;
      margin-top: -1px;
    }
  }
}

}

