<section class="searchBar">

    <mat-form-field appearance="outline" class="searchField">
        <input matInput type="search" autocomplete="off" (click)="results && !showResults ? showResults = true : showResults = false" [formControl]="searchControl" placeholder="Buscar" >
        <!-- <button mat-button class="blue"><mat-icon>search</mat-icon></button> -->
    </mat-form-field>

    <div class="open_search"><mat-icon>search</mat-icon></div>

</section>

<client-results *ngIf="tipoCuentaID == 1 && showResults" [results]="results" class="results" (clickOutside)="showResults = false" [exclude]="'.searchBar'"></client-results>
<relevador-results *ngIf="tipoCuentaID == 2 && showResults" [results]="results" class="results" (clickOutside)="showResults = false" [exclude]="'.searchBar'"></relevador-results>
<delegado-results *ngIf="tipoCuentaID == 3 && showResults" [results]="results" class="results" (clickOutside)="showResults = false" [exclude]="'.searchBar'"></delegado-results>
<editor-results *ngIf="tipoCuentaID == 4 && showResults" [results]="results" class="results" (clickOutside)="showResults = false" [exclude]="'.searchBar'"></editor-results>
<superadmin-results *ngIf="tipoCuentaID == 999 && showResults" [results]="results" class="results" (clickOutside)="showResults = false" [exclude]="'.searchBar'"></superadmin-results>
