import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ServerStatus } from 'src/app/core/enums/ServerStatus';
import { CancelDialogService } from 'src/app/core/services/cancel-dialog.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Global } from 'src/app/global/Global';
import { SubmenuChange } from '../../submenu/submenu.component';
import { SubmenuService } from '../../../core/services/submenu.service';
import { WebSocketService } from 'src/app/core/services/web-socket.service';
import { SocketMensaje } from 'src/app/core/types/SocketMensaje';
import { getDecodedToken } from 'src/app/core/helpers/tokenReader';
import tiposDeCuentas from 'src/app/core/enums/AccountTypes';
// import { io, Socket } from 'socket.io-client';
import { ConfirmarPopupComponent } from 'src/app/global/confirmar-popup/confirmar-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { TopBarService } from './services/topbar.service';
import { Subscription } from 'rxjs';
import { LeftBarService } from '../leftbar/services/leftbar.service';
import { CajaService } from 'src/app/core/services/caja.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CajaGlobalService } from 'src/app/private/core/services/caja/caja-global.service';

@Component({
standalone: false,
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

export class TopbarComponent implements OnInit{
  @Output() datosCargados = new EventEmitter();
  @Output() nuevoPedido = new EventEmitter();
  @Output() menuChange: EventEmitter<SubmenuChange> = new EventEmitter();
  @Output() procesarCambioSubmenuPedido = new EventEmitter();
  @Output() noMostrarPedidos = new EventEmitter()

  @Input() puedeNavegar:boolean = true;

  @Input() modificarSubMenuSeleccionado = 0

  @ViewChild('submenucomp') submenu: any;

  
  public tipoCuenta: string  = "";
  public tipoCuentaID: number; 
  public bandera:    boolean = false;
  public cargando:   boolean = true;
  public mobile:     boolean = false;

  public ind: number;

  public badges = {
    'Ofertas': 0,
    'Entrega Material': 0
  }

  public chat:boolean =false;
  public notifications:boolean =false;
  services:any
  conocimientos:any
  conocimientosE:any
  iconosConocimiento:any[] = []
  superadmin: boolean = false;
  superAdmin:number = 0
  notificaciones: any[] = [];
  datosCuentaCorriente:any

  nuevaNotificacion = {};

  ocultarNuevoPedido = false
  ocultarPedidos = false

  mostrarToolTip: boolean = window.innerWidth <= 590;
  tooltipNuevoPedido = 'Crear pedido'
  

  @HostListener('window:resize', ['$event'])
  
  onResize(event) {
    this.mostrarToolTip = window.innerWidth <= 590;
  }

  /* Agrego esto momentaneamente para cambiar el tab seleccionado desde otros lados. */
  private suscripciones = new Subscription();

  constructor(
    private router: Router,
    private topbarService: TopBarService,
    private notificationService: NotificationService,
    private _activador: CancelDialogService,
    private submenuService: SubmenuService,
    private elementRef: ElementRef,
    public dialog: MatDialog,
    private webSocketService: WebSocketService,
    private _cajaService: CajaService,
    private _cajaGlobalService: CajaGlobalService,
    private _snackBar: MatSnackBar,
    /* Agrego esto momentaneamente para cambiar el tab seleccionado desde otros lados. */
    private leftBarService: LeftBarService
  ) { 
      this.webSocketService.listen('nueva-notificacion').subscribe((message:any) => {
        this.nuevaNotificacion = message['payload']['data'];
      }) 

      const decodedToken = getDecodedToken();
    
      Global.nombre = decodedToken.user.nombre;
      Global.apellido = decodedToken.user.apellido;
      Global.tipoCuenta = decodedToken.user.rol.toLowerCase();
      Global.tipoCuentaID = decodedToken.user.tipo;

      Global.uuidCuenta = decodedToken.user.uuid;
      Global.uuidMaestra = decodedToken.user.uuidCuenta;
      
      /*Global.permisos = JSON.parse(decodedToken.user.permisos.trim());*/

      this.tipoCuenta = tiposDeCuentas[(decodedToken.user.tipo==999)?this.tipoCuenta.length-1:decodedToken.user.tipo-1];     
    
      this.datosCargados.emit(true);
      this._activador.esperarCarga.next(true);
      this.cargando = false;
      
      const colorTopBar: string[][] = [
        ['#05abce', '#567bff'], // Cliente // ['#0c79ed', '#5aa2ef'] // Cliente-viejo
        ['#3a9985', '#5b9eb5'], // Relevador 
        ['#382b8b', '#717dd3'], // Delegado
        ['#d59456', '#f27063'], // Editor
        ['#2e3c76', '#2c5887'], // SuperAdmin
        ['#0F72DC', '#0F72DC']  // Default        
      ];

    const tipoCuenta = Global.tipoCuentaID==999?4:Global.tipoCuentaID-1;
    this.elementRef.nativeElement.style.setProperty('--color1',colorTopBar[tipoCuenta][0]);
    this.elementRef.nativeElement.style.setProperty('--color2',colorTopBar[tipoCuenta][1]);

    const indInicial = this.router?.getCurrentNavigation()?.extras?.state?.['indice'];
    this.ind = (indInicial || indInicial == 0) ? indInicial : 0;
  }

  ngOnInit(): void {    
    this.realizarAccionesCuentaCorriente()

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      localStorage.setItem('subioArchivo','0')
    });

    this.tipoCuenta = Global.tipoCuenta;
    if(Global.tipoCuentaID == 999){
      this.tipoCuenta = "SuperAdmin"
    };

    this.tipoCuentaID = Global.tipoCuentaID
    
    this.enviarSocketId();

    this.webSocketService
      .listen('notificacionPrueba')
      .subscribe((data: SocketMensaje) => {
        this.notificaciones.push(data.payload);
    });
  
    this.webSocketService.listen("enviarNotificacion")
    .subscribe((data: SocketMensaje) => {
      this.notificaciones.push(
        data.payload
      )
    })

    this.agregarSuscripcions()
  }

  agregarSuscripcions(){
      /* Agrego esto momentaneamente para cambiar el tab seleccionado desde otros lados. */
    this.suscripciones.add(this.leftBarService.opcionSeleccionada.subscribe((opcion:number)=>{
      if(opcion == 3){
        this.irOfertas();
      }
    }))
  }

  async realizarAccionesCuentaCorriente(){
    await this.getCuentaCorriente()
    this.verificarPlazosDePagoCuentaCorriente()
  }

  verificarPlazosDePagoCuentaCorriente(){
    if(this.datosCuentaCorriente?.cuentaCorriente == 1 && Number(this.datosCuentaCorriente.balance) < 0){
      const fecha = new Date()
      const fechaBalance = new Date(this.datosCuentaCorriente.fechaBalance)
      const dia = fecha.getDate()
      if(dia >= 5 && (fecha.getMonth() != fechaBalance.getMonth())){
        this.leftBarService.aplicarSancionesCuentaCorriente(1)
        this.ocultarNuevoPedido = true
        this.mostrarToolTip = true
        this.tooltipNuevoPedido = 'Debe realizar el pago de la cuenta corriente para realizar nuevos pedidos.'
      }
      
      if(dia >= 10 && (fecha.getMonth() != fechaBalance.getMonth())){
        this.leftBarService.aplicarSancionesCuentaCorriente(2)
        this.ocultarNuevoPedido = true
        this.mostrarToolTip = true
        this.tooltipNuevoPedido = 'Debe realizar el pago de la cuenta corriente para realizar nuevos pedidos.'
      }
    }
  }

  async getCuentaCorriente(){
    let res:any = await this._cajaService.getCuentaCorriente()
    if(res.status == 200){
      this.datosCuentaCorriente = res.datosCuentaCorriente
    }
  }

  getDatosCuentaCorriente(){
    this._cajaGlobalService.getCuentaCorriente().subscribe({
      next: (respuesta) => {
        this.procesarRespuestaCuentaCorriente(respuesta)
      },error: (error) => {
        this._snackBar.open(error.message, "Cerrar", {
          duration: 5000,
          panelClass: ['error']
        });
      }
    })
  }

  procesarRespuestaCuentaCorriente(respuesta){
    // this.datosCuentaCorriente = respuesta.cuentaCorriente
  }

  ngOnDestroy(): void {
    /* Agrego esto momentaneamente para cambiar el tab seleccionado desde otros lados. */
    this.suscripciones.unsubscribe();
  }

  enviarSocketId() {
    const mensaje = {
      destinos: ["server"],
      payload: {
        uuidUsuario: Global.uuidCuenta
      }
    };
    this.webSocketService.emit('enviarId', mensaje);
  }

  isClient(){
    return this.tipoCuenta=='cliente';
  }

  verificarUsuario(){            
    if(Global.tipoCuentaID === 1){
      return true
    }else{
      return false
    }
  }

  crearNuevoPedido()
  {
    if(this.router.url != '/home'){
      this.router.navigateByUrl('/home').finally(() => {
        setTimeout(() => {
          this.topbarService.realizarNuevoPedido();
        },0)
      })
    }else{
      this.topbarService.realizarNuevoPedido();
    }
   
  }

  irInicio() {

    const subioArchivos = Number(localStorage.getItem('subioArchivo'))

    if(subioArchivos == 1){
      const dialog = this.dialog.open(ConfirmarPopupComponent,{
        height:'auto',
        width:'auto',
        data:{mensaje:'Todavia no finalizaste la subida de archivos, ¿Estas seguro que queres salir?'}
      })

      dialog.afterClosed().pipe().subscribe((opcion) => {
        if (opcion == "SI") {
          const route = /*Global.superadmin*/Global.tipoCuentaID == 999
          ? 'superadmin'
          : this.tipoCuenta === 'cliente'
          ? 'home'
          : this.tipoCuenta;
          return this.submenuService.navigateToProfileMainScreen(route);
        }
      })
    }else{
      this.topbarService.volverAInicio()
      const route = /*Global.superadmin*/Global.tipoCuentaID == 999
      ? 'superadmin'
      : this.tipoCuenta === 'cliente'
      ? 'home'
      : this.tipoCuenta;
      this.submenuService.navigateToProfileMainScreen(route);
      this.router.navigateByUrl('/')
    }
  }

  triggerNotify() {
    if (!this.bandera) {
      this.bandera = true
      this.notificationService.triggerNotify().then((res: any) => {
        if (res.status != ServerStatus.SUCCESSFUL) {
          console.log("Error en traer Notificacion", res.err)
        }
        this.bandera = false
      })
    }
  }

  menuTabChange(event: SubmenuChange){
    if(Global.tipoCuentaID == 1){
      this.leftBarService.cambiarOpcion(event.optionIndex)
    }
    
    this.procesarCambioSubmenuPedido.emit(false)
  
    if(this.ocultarPedidos) this.noMostrarPedidos.emit(true)
    else this.noMostrarPedidos.emit(false)
    
    if(this.puedeNavegar) {
      sessionStorage.clear();
      this.menuChange.emit(event as SubmenuChange);
    }
  }

  irOfertas(){
    this.submenu?.cambiarOpcion(3, 'Ofertas')
  }

  cambiarOpcion(){    
    this.submenu.cambiaropc();
  }

  openChat($event:any){
    this.chat = $event;
  }

  openNotifications($event){
    this.notifications=$event;
  }

  getFrase(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

}


