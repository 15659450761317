import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/core/services/global.service';
import { WebSocketService } from 'src/app/core/services/web-socket.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { MensajeChat, Participante } from 'src/app/core/interfaces/chat-seleccionado.interface';
import { SocketMensaje } from 'src/app/core/types/SocketMensaje';
import { Global } from 'src/app/global/Global';
import { OrderService } from 'src/app/core/services/order.service';
import { DelegadoService } from 'src/app/core/services/delegado.service';
import { EditorService } from 'src/app/core/services/editor.service';
import { CuentaService } from 'src/app/core/services/cuenta.service';
import { RelevadorService } from 'src/app/core/services/relevador.service';
import { superAdminService } from 'src/app/core/services/super-admin.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Recursos } from 'src/app/global/Recursos';
import { ChatTopbarService } from './services/chat.service';
import { ChatDesplegableService } from 'src/app/global/chat-desplegable/services/chatDesplegable.service';
import { Utils } from 'src/app/global/Utils';

interface Mensaje {
  contenido:             string;
  fecha_enviado_mensaje: string;
  fechaHoraEnviado: string;
  idMensaje:             number;
  idPedido:              number;
  idUsuarioReceptor:     number;
  imagenUser:            null;
  mensajeNoVisto:        number;
  nombreUsuario:         string;
  usuarioEmisor:         number;
  vistoId: number;
}

@Component({
standalone: false,
  selector: 'chat-activity',
  templateUrl: './chat-activity.component.html',
  styleUrls: ['./chat-activity.component.scss']
})

export class ChatActivityComponent implements OnInit {
  showChat: boolean = false;
  cantMensajesSinLeer: number = 0;
  mensajes: Mensaje[];
  cardOpen = false;
  claseFiltroTodos: string = 'activo';
  claseFiltroNoLeido: string = 'no-activo';

  amountOfNotifications: number = 10;
  offsetNotifications: number = 0;
  totalNotificaciones: number = 0;
  idPedido = 0
  pedido:{nroPedido:number,servicios:[]}

  currentGroupIndex: number = 0;

  cuentaLogueada: any = {
    codigoRol: '',
  };

  esSuperAdmin = false
  tipoCuenta = Global.tipoCuentaID

  mensajesPorLote = 5;

  participantes:any
  chatsGrupales: any[] = [];
  chatGrupalSeleccionado: any[] = []
  salaChat: any;
  chatSeleccionado: Participante;
  tipoChatAbierto: string = "lobby";
  participanteSuperadmin: Participante;
  salaUsuarioIniciado: Participante;
  chatAbiertoId: number;
  chatActivo: boolean = false;
  pedidoFiltrado:boolean = false
  mensajesSinLeer:any

  uuidCuenta:string = ''
  superAdmin:any 

  cargando = true
  animacion: 'fadeIn' | 'fadeOut' | '' = '';
  
  avatar = Recursos.imagenAvatar;

  page = 1;
  pageSize = 10;
  cantidadPedidosChat = 0
  cargandoScroll = false

  pedidosParticipa: any[] = [];
  salaChatsParticipa: {id:number, notificacion:boolean, idSalaChat?:number, fechaHora?:Date}[] = [];

  @Output() openChat = new EventEmitter<boolean>();
  @Input() openOtherMenu: boolean = false;
  @ViewChild('scrollableDiv') scrollableDiv: ElementRef;

  @HostListener('window:scroll',['$event'])
  onScroll(event) {
   if (this.scrollableDiv) {
     const scrollableDiv = this.scrollableDiv.nativeElement;
     let scrollHeight = scrollableDiv.scrollHeight;
     let scrollTop = scrollableDiv.scrollTop;
     const clientHeight = scrollableDiv.clientHeight;

     if (scrollHeight - scrollTop === clientHeight && this.cantidadPedidosChat != this.pedidosParticipa.length && !this.cargandoScroll && !this.pedido.nroPedido ) {
         this.cargandoScroll = true
         this.scroll.next(null)
     }
   }
 }

  private scroll: Subject<any> = new Subject<any>();
  private clickOutsideListener: () => void;

  constructor(
    private _globalService: GlobalService,
    private elementRef: ElementRef,
    private webSocketService: WebSocketService,
    private chatService: ChatService,
    private ordersService: OrderService,
    private cuentaService: CuentaService,
    private globalService: GlobalService,
    private _chatTopbarService: ChatTopbarService,
    private _chatDesplegableService: ChatDesplegableService
  ) {

    this._chatTopbarService.chat.subscribe((idPedido:number) => {
      if(idPedido != 0){
        this.idPedido = idPedido
        this.abrirMensajes()
      }else{
        this.idPedido = null
        this.cerrarMensajes()
      }
    })

    this.globalService.cerrarChatTopbar.subscribe((cerrar:boolean) => {
       if(cerrar){
        this.tipoChatAbierto = 'lobby'
       }
    })
  }

  ngOnInit() {
    // this.scroll.pipe(
    //   debounceTime(600), 
    // ).subscribe(value => {
    //   this.page ++
    //   this.getChats();
    //   this.cargandoScroll = false
    // });

    const seccion = document.getElementById('seccion')

    document.addEventListener('click', this.onClickFuera.bind(this))

    this.clickOutsideListener = this.onClickDentro.bind(this);
    seccion.addEventListener('click', this.clickOutsideListener);

    this.getTipoCuenta();

    this.cuentaLogueada.codigoRol == 'SA'?this.esSuperAdmin = true:this.esSuperAdmin = false

    this.inicializarSocketChat()
    this.getCantSinLeer();
  }

  async getTipoCuenta() {
    try {
      const res = await this.cuentaService.getTipoCuenta();
      this.cuentaLogueada = res;
      this.uuidCuenta = Global.uuidCuenta
    } catch (error) {}
  }

  inicializarSocketChat(){
    // this.webSocketService.listen('mensaje-enviado').subscribe(async (data: SocketMensaje) => {
    //     const mensajeRecibido: MensajeChat = data.payload;
    //     if (mensajeRecibido.usuarioEmisor.uuid !== Global.uuidCuenta) {
    //       this.getCantSinLeer()
    //       this.salaChat()
    //     }
    // });

    this.webSocketService.listen('mensaje-enviado').subscribe((data: any) => {
      let uuidEncontrado = null
      const mensajeRecibido: MensajeChat = data?.payload;
      mensajeRecibido.uuid = data?.payload?.usuarioEmisor?.uuid
      let encontrado

      if(this.mensajes)
        encontrado = this.mensajes.findIndex((mensaje:any) => mensaje?.id == data?.payload?.id)

      if(data?.destinos){
        uuidEncontrado = data.destinos.filter((destino:any) => destino == Global.uuidCuenta)
      }

      if(encontrado == -1 && uuidEncontrado && data?.payload?.usuarioReceptor?.idSalaChat == this.salaChat){
          this.getCantSinLeer()
          this.salaChat()
      }

    })
  }

  getCantSinLeer() {
    this.cantMensajesSinLeer = 0
    this.chatService.getMensajesSinLeerTopbar().then((res:any) => {
      if(res.status == 200){
        for (let i = 0; i < res.cantidad.length; i++) {
          this.cantMensajesSinLeer += res.cantidad[i].cantidadSinLeer
          const indice = this.salaChatsParticipa.findIndex((sala:any) => sala.id == res.cantidad[i].idPedido)
          if(indice != -1){
            this.salaChatsParticipa[indice].notificacion = true
          }
        }

        this.mensajesSinLeer = res

        this.salaChatsParticipa.sort((a, b) => {
          if (a.notificacion && !b.notificacion) {
              return -1;
          } else if (!a.notificacion && b.notificacion) {
              return 1;
          } else {
              return 0;
          }
      });
      }
    })
  }

  getChatSuperAdmin(){
    this.chatService.getParticipanteSuper(null).then((res:any) => {
      this.superAdmin = res
    })
  }

  abrirMensajes() {
    setTimeout(() => {
      this.scrollableDiv.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    },1)
    this.mostrarChat()


    this.getChats(this.idPedido)
    this._chatDesplegableService.cerrarChatDesplegable()
  }

  mostrarChat(){
    this.animacion = 'fadeIn';
    this.cardOpen = !this.cardOpen;
  }

  async getChats(idPedido?:number){
    this.pedido = {nroPedido:idPedido, servicios:[]}
    if(!idPedido || idPedido == 0 && this.cantidadPedidosChat != this.pedidosParticipa.length && !this.pedidoFiltrado){
      await this.getPedidos();
    }

    await this.getSalaChats(idPedido);

    this.getChatSuperAdmin()
  }

  async getPedidos() {
    const filtros = {
      page_number: this.page,
      page_size: this.pageSize
    }

    this.pedidoFiltrado = false

    return new Promise<void>((resolve) => {
      this.ordersService.getChatPedido(filtros, Global.tipoCuentaID).subscribe((res: any) => {
          for (let i = 0; i < res?.pedidos?.length; i++) {
            const pedido = this.pedidosParticipa.filter((pedidoParticipa:any) => pedidoParticipa == res.pedidos[i].pedidoID)
            if(pedido.length == 0){
             this.pedidosParticipa.push(res.pedidos[i].pedidoID);
            }
          }

        this.cantidadPedidosChat = res.cantidadPedidos
         resolve();
      });
    });
  }
  
  async getSalaChats(idPedidoFiltro?:number) {
    this.salaChatsParticipa = []
    if(idPedidoFiltro && idPedidoFiltro != 0){
      this.pedidoFiltrado = true
      const responses:any = await this.chatService.getChatActivo(idPedidoFiltro.toString(),null);
      this.salaChatsParticipa = responses.flat().sort((a, b) => {
        return b.salaUsuarioId - a.salaUsuarioId;
      }); 

      await this.getParticipantes()
      this.getCantSinLeer()
    }else{
      this.pedidoFiltrado = false
      let pedidoFiltro = this.pedidosParticipa?.join(',')
      if(pedidoFiltro == '') pedidoFiltro = '0'
      this.chatService.getChatActivo(pedidoFiltro,null,true).then((res:any) => {
        for (let i = 0; i < res.length; i++) {
          const idPedido = this.salaChatsParticipa.findIndex((sala:any) => sala.id == res[i].idPedido)
          if(idPedido == -1 && res[i].activo == 1){
            this.salaChatsParticipa.push({id: res[i].idPedido,notificacion:(res[i].idMensajeVisto && !res[i].fechaHora)?true:false})
          }
        }

        this.salaChatsParticipa.sort((a, b) => {
          return a.id - b.id;
        });

        this.getCantSinLeer() 
        this.cargando = false
        // setTimeout(() => {
        //   this.cargando = false 
        // },1)
      });
    }

    
    this.chatService.updateMensajesVisto().then((res:any) => {
      if(res.status == 200){
        this.mensajesSinLeer = null
      }
    })
  }

  ordenarMensajes() {
    this.mensajes.sort((a, b) => {
      const fechaA = new Date(a.fechaHoraEnviado).getTime();
      const fechaB = new Date(b.fechaHoraEnviado).getTime();
      return fechaB - fechaA;
    });
  }



  abrirChat(estado: boolean) {
    this.openChat.emit(estado);
  }

  mensajesNoLeidos(mensajes: Mensaje[]): Mensaje[] {
    return mensajes.filter((mensaje) => mensaje.mensajeNoVisto !== null);
  }

  onScrollCheck(event: any) {
    const container = event.target;
    const totalSalaChats = this.salaChatsParticipa.length;
    const batchSize = this.mensajesPorLote;

    if (
      container.scrollHeight - container.scrollTop === container.clientHeight &&
      (this.currentGroupIndex + 1) * batchSize < totalSalaChats
    ) {
      this.currentGroupIndex += 1;
      // this.getMensajesSinLeer();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.openOtherMenu) {
    //   if (this.showChat && this.openOtherMenu) {
    //     this.abrirChat(!this.openOtherMenu);
    //   }
    // }
  }

  getNoteType(type: string) {
    if (!type) return '';
    const splittedType = type.split('_');
    if (splittedType.length < 2) return '';
    return splittedType[1];
  }

  cerrarMensajes() {
    this.chatAbiertoId = null
    this.idPedido = null
    localStorage.removeItem('idPedido')
    localStorage.removeItem('chat')
    this.animacion = 'fadeOut';
    setTimeout(() => {
      this.cardOpen = false;
    }, 100);
  }

  async enviarVisto(mensaje: Mensaje) {
    const body = {
      idMensaje: mensaje.idMensaje,
      idUsuarioVisto: mensaje.idUsuarioReceptor,
    };
    try {
      await this.chatService.postVistoMensajeGrupal(body);
      mensaje.mensajeNoVisto = null;
      this.getCantSinLeer();
    } catch (error) {}
  }

  private onClickFuera(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      const elemento = event.target as HTMLElement
      this.cerrarMensajes();
    }
  }

  private onClickDentro(event){
    event.stopPropagation();
  }

  async getParticipantes() {
    this.participantes = []
    this.chatsGrupales = []
    try {
      if (this.cuentaLogueada.codigoRol == 'SA') {
        this.salaChatsParticipa.forEach(async salaChat => {
          const participantes = await this.chatService.getAllParticipantes(
            salaChat
          );
          const datosChatGrupal = { nombre: 'Chat Grupal - ' };
          if (participantes.length === 1) {
            participantes[0].imagen = Utils.getRutaPublicaMiniatura(participantes[0].imagen)
            this.participantes.push(...participantes);
          } else {
            participantes.forEach((participante) => {
              datosChatGrupal.nombre += participante.rol + ' - ';
            });

            participantes.forEach((participante) => {
              participante.imagen = Utils.getRutaPublicaMiniatura(participante.imagen)
            })
            
            this.chatsGrupales.push(participantes);
          }

          this.agregarUltimoMensajeChatGrupal()

        })
      } else {
        this.salaChatsParticipa.forEach(async (salaChatParticipa:any) => {
          const participantes = await this.chatService.getParticipantes(salaChatParticipa);
          if (participantes.length === 1) {
            participantes[0].imagen = Utils.getRutaPublicaMiniatura(participantes[0].imagen)
            this.participantes.push(...participantes);
          } else if (participantes.length > 1) {
            participantes.forEach((participante) => {
              participante.imagen = Utils.getRutaPublicaMiniatura(participante.imagen)
            })
            
            this.chatsGrupales.push(participantes);
          }

          this.agregarUltimoMensajeChatGrupal()
         });

        this.salaUsuarioIniciado = await this.chatService.getSalaUsuarioIniciado(
          this.salaChatsParticipa[0].idSalaChat,
          Global.uuidCuenta
        );

      }
      
      setTimeout(() => {
        this.cargando = false
      },200)
    } catch (error) {}
  }

  agregarUltimoMensajeChatGrupal(){
    for (let i = 0; i < this.chatsGrupales.length; i++) {
      const mensajeSinLeer = this.chatsGrupales[i].find((participante:any) => participante.ultimoMensaje.horaVisto == null)
      if(mensajeSinLeer){
        this.chatsGrupales[i].ultimoMensaje = mensajeSinLeer
      }else{
      for (let j = 0; j < this.chatsGrupales[i].length; j++) {
        if(j == 0){
          this.chatsGrupales[i].ultimoMensaje = this.chatsGrupales[i][j].ultimoMensaje
        }
        if(this.chatsGrupales[i][j].ultimoMensaje?.horaVisto > this.chatsGrupales[i].ultimoMensaje?.horaVisto){
          this.chatsGrupales[i].ultimoMensaje = this.chatsGrupales[i][j].ultimoMensaje
        }
      }
      }
  }
  }

  setSalaChatByParticipantes(participantes) {
    if (Array.isArray(participantes)) {
      this.salaChat = participantes[0].idSalaChat
      this.chatGrupalSeleccionado = participantes;
    }

    return;
  }

  cambiarChat(tipoChatAbierto: string, participante: Participante)
  {

    this.setSalaChatByParticipantes(participante)
    if (participante !== null && !Array.isArray(participante)) {
      this.chatGrupalSeleccionado = null;
      this.salaChat = participante.idSalaChat;
    }
    this.chatSeleccionado = participante;
    this.tipoChatAbierto = ''

    if(participante && participante.ultimoMensaje)
    participante.ultimoMensaje.fechaVisto = new Date()

    

    setTimeout(() => {

      if(this.chatSeleccionado){
        this.getCantSinLeer()

        this.chatService.updateMensajesVistoUsuario(participante.idSalaUsuario).then((res:any) => {
          if(res.status == 200){
            for (let i = 0; i < this.salaChatsParticipa.length; i++) {
              if(!this.salaChatsParticipa[i].fechaHora){
                this.salaChatsParticipa[i].fechaHora = new Date()
              }
            }

            const indice = this.participantes.findIndex((participanteChat) => participanteChat.idSalaUsuario == participante.idSalaUsuario)
            if(indice != -1) this.participantes[indice].fechaHora = new Date(), this.participantes[indice].ultimoMensaje.fechaHora = new Date(), this.participantes[indice].ultimoMensaje.horaVisto = new Date()

            // this.getSalaChats()
          }
        })
      }
    },200)

    setTimeout(() => {
      this.tipoChatAbierto = tipoChatAbierto;
      this._globalService.cerrarChat.emit(true)
      if(tipoChatAbierto != 'lobby'){
        this.cerrarMensajes()
      }
    },100)
  }

}


