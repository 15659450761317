import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class RoutingServiceSuperAdmin {

    private _opcion: Subject<number> = new Subject<number>();

    cambiarOpcion(opcion:number) {      
        this._opcion.next(opcion);  
    }

    get opcion(){  
        return this._opcion.asObservable();
    }


}