import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/global/Global';
import { SearchBarService } from './search-bar.service';

@Component({
  standalone: false,
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchBarComponent implements OnInit, OnDestroy {

  searchControl: FormControl;
  results: any | null;
  showResults: boolean = false;
  tipoCuentaID = 0

  private _unsubscribeAll: Subject<any>;


  constructor(private router: Router, private searchService: SearchBarService) {
    this.results = null;
    this._unsubscribeAll = new Subject();
    this.searchControl = new FormControl();
    
  }
  
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  
  ngOnInit(): void {
    this.tipoCuentaID = Global.tipoCuentaID
    this.searchControl.valueChanges.pipe(
      debounceTime(500),
      takeUntil(this._unsubscribeAll),
      map((value) => {
        if (!value || value.length == 0) {
          this.results = null;
        }

        return value;
      }),
      filter((value) => {
        return value && value.length > 0
      }),
    ).subscribe((value) => {
      this.showResults = false;
      if (value.length > 0) {
        this.searchService.search(value).subscribe((response: any) => {
          if (response.status == 200) {
            this.showResults = true;
            this.results = response.results;
          }
        })
      }
    })

  }


}
