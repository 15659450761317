import { CommonModule } from '@angular/common';
import { Input, NgModule } from '@angular/core';
import { LogoModule } from '../../Recursos graficos/logo/logo.module';
import { SearchBarModule } from '../../controls/inputs/search-bar/search-bar.module';

import { TopbarComponent } from './topbar.component';
import { NotificationsModule } from '../../controlesViejosARevisar/notifications/notifications.module';
import { ProfileModule } from '../../controlesViejosARevisar/profile/profile.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChatActivityModule } from '../../controls/chat-activity/chat-activity.module';
import { ClientMenuModule } from '../../controlesViejosARevisar/client-menu/client-menu.module';
import { SubmenuModule } from '../../submenu/submenu.module';

@NgModule({
    declarations: [
        TopbarComponent
       
    ],
    imports: [
        CommonModule,
        LogoModule,
        MatIconModule,
        SearchBarModule,
        NotificationsModule,
        ProfileModule,
        MatTooltipModule,
        ChatActivityModule,
        ClientMenuModule,
        SubmenuModule,
        MatTooltipModule
    ],
    exports:[
        TopbarComponent
    ]
})
export class TopbarModule
{
}
