import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/core/interfaces/DialogData';

@Component({
standalone: false,
  selector: 'app-confirmar-popup',
  templateUrl: './confirmar-popup.component.html',
  styleUrls: ['./confirmar-popup.component.scss']
})
export class ConfirmarPopupComponent implements OnInit {

  datos!: any;

  constructor(
    private elRef : ElementRef,
    public dialogRef: MatDialogRef<ConfirmarPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  )
  {
  }
  ngOnInit(): void
  {
    this.datos = {...this.data};

    this.elRef.nativeElement.style.setProperty('--colorSi', (this.datos.colorSi?this.datos.colorSi:"green"))
    this.elRef.nativeElement.style.setProperty('--colorNo', (this.datos.colorNo?this.datos.colorNo:"#ba0900" ))
    this.elRef.nativeElement.style.setProperty('--letraNo', (this.datos.letraNo?this.datos.letraNo:"white" ))
    this.elRef.nativeElement.style.setProperty('--letraSi', (this.datos.letraSi?this.datos.letraSi:"white" ))

  }

  onClick()
  {
    this.dialogRef.close("SI")
  }
  onNoClick()
  {
    this.dialogRef.close("NO")
  }
}
