<section class="navBar">
    <nav #mattab>
      <a *ngFor="let link of links; let i = index" 
      (click)="cambiarOpcion(i, link)"
      (click)="event(i,link)"
      [ngClass]="{
        'active': activeLink == link,
        'categoriaSP': rolUsuario == 'sa' && linksDiabled[i]
      }"
      [matTooltip]="mostrarTooltip() ? link : null" 
      >
        <mat-icon class="nav-i" [matBadgeHidden] = "badges[link] <= 0" [matBadge]="badges[link]" matBadgeOverlap="false" matBadgeSize="small" matBadgeColor="warn">
          {{icons[i]}}
        </mat-icon>
        <p class="nav-p" [matBadgeHidden] = "badges[link] <= 0" [matBadge]="badges[link]" matBadgeOverlap="false" matBadgeSize="small" matBadgeColor="warn" >
           {{ link }}
        </p>
      </a>
    </nav>
</section>
